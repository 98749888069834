.tableBodyLinkedIn,
.tableHeaderLinkedIn,
.tableBodyLoaderLinkedIn {
  .createdDate {
    width: 13% !important;
    text-overflow: unset !important;
  }
  .searchUrl {
    width: 20% !important;
    align-items: center;
    justify-content: center;
    display: flex !important;
    svg {
      width: 15px;
      height: 15px;
    }
  }
  .searchUrl svg path {
    stroke: #9e9e9e !important;
  }

  .status {
    @include flex-center;
    gap: 4px;
  }
  // .actions {
  //   width: 7%;
  //   cursor: pointer;
  //   text-align: center;
  //   position: relative;
  //   margin: 0 auto;
  //   .pauseStop {
  //     @include flex-align-center;
  //     span:first-child {
  //       margin-right: 8px;
  //     }
  //     .stopButton {
  //       svg path {
  //         color: red;
  //       }
  //       .customTooltip {
  //         .sharpPointer {
  //           right: -13px;
  //         }
  //         .tooltipBody {
  //           left: 37px;
  //           right: unset;
  //         }
  //       }
  //     }
  //   }
  //   .customTooltip {
  //     background: #393a3d;
  //     .sharpPointer {
  //       border-right: 1px solid $c-33;
  //       border-top: 1px solid $c-33;
  //       background: #393a3d;
  //       left: unset;
  //       right: 44px;
  //       top: -20px;
  //       border-radius: unset;
  //       padding: 5px;
  //     }
  //     .tooltipBody {
  //       width: fit-content;
  //       right: -10px;
  //       white-space: nowrap;
  //       background: #393a3d;
  //       top: -33px;
  //       right: 48px;
  //       left: unset;
  //       .tooltipText {
  //         color: $white;
  //         padding: 8px;
  //       }
  //     }
  //   }
  // }
  .progressStatusWrapper {
    display: flex;
    flex-direction: row;
    // width: 15%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 5%;
    font-size: 14px;
    justify-content: center;
    .progressStatus {
      display: flex;
      // width: 15%;
      text-align: center;
      justify-content: center;
      align-items: center;
      .statusDot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 6px;
      }
    }
  }
  .status {
    width: 15%;
    text-align: center;
    justify-content: center;
    .statusDot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 2px !important;
    }
  }
  .totalContacts,
  .contactsExtracted,
  .contactsProgress,
  .contactsPerDay {
    width: 20% !important;
    text-align: center;
  }
  .totalContacts {
    width: 20% !important;
  }
  .contactsProgress {
    width: 20% !important;
    align-items: center !important;
    .progressbarWrapper {
      margin-left: 1px;
      display: flex;
      justify-content: center;
    }
  }
}
.tableHeaderLinkedIn {
  .createdDate {
    width: 12% !important;
  }
}
.tableBodyLinkedIn {
  .searchUrl {
    color: $blue-violet;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .ownerName {
    width: 10% !important;
  }
}
