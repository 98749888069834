.linearProgressBar {
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 15px;
  overflow: hidden;
  position: relative;

  .backgroundProgress {
    width: 100%;
    height: 100%;
    background-color: #ddd;
    animation: move 8s linear infinite;
    background-size: 40px 40px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .linearProgress {
    height: 100%;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 0;
    &.applyTransition {
      transition: width 2s ease-in-out;
    }
  }
}
