.headerGlobalNotification {
  position: relative;
  @include flex-align-center;
  width: 100%;
  height: 40px;
  background-color: $hb-sandal-yellow;
  font-size: 14px;
  color: $c-44;
  text-align: center;
  .message {
    @include flex-center();
    justify-content: center;
    flex-direction: column;
  }
  .upgradeText {
    color: $orange-btn;
    cursor: pointer;
    margin: 0 4px;
  }
}
