.creditsUsageSection {
  @extend %relBlock;
  height: 100%;
  .creditsUsageHeader {
    position: relative;
    @include flex-center;
    justify-content: space-between;
    height: 55px;
    padding: 0 30px;
    border-bottom: 1px solid $c-ec;
    h6 {
      position: relative;
      display: block;
      font-size: 16px;
      font-family: $font-regular;
      color: $c-66;
      font-weight: 400;
    }
    .flexEnd {
      @include flex-center;
      justify-content: flex-end;
      .refreshCreditsUsage {
        min-width: 100px;
        cursor: pointer;
        margin: auto 0;
        padding: 0 12px;
        color: $c-66;
        background: $c-f5;
        border: 1px solid $c-f5;
        font-size: 14px;
        height: 28px;
        border-radius: 35px;
        .iconRight i {
          font-size: 14px;
          padding-left: 5px;
        }
        &.loading {
          width: 30px !important;
          height: 30px !important;
          min-width: unset !important;
          .load-text {
            width: 18px;
            height: 18px;
            border-color: $blue-violet;
          }
        }
        &:hover {
          color: $c-22;
          border-color: $c-ee;
          transition: all 0.3s ease;
          .iconRight i {
            color: $c-22;
          }
        }
      }
    }
  }
  .creditsUsageBody {
    @extend %relBlock;
    padding: 20px 15px;
    overflow-y: auto;
    height: calc(100% - 55px);
    @media (max-width: 992px) {
    }
    .cardWrapper {
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: space-around;

      .cardContainer {
        display: flex;
        flex-direction: column;
        min-width: 315px;
        max-width: 340px;
        background: white;
        border-radius: 10px;
        border: 1px solid $c-ec;
        .add-credits {
          .addCreditsBtn {
            display: block;
            text-align: center;
            padding: 10px;
            background: $blue-transparent;
            color: $blue-violet !important;
            font-size: 16px;
            width: 100%;
            border-radius: 0 0 10px 10px;
            transition: 0.3s;
            margin-bottom: 0;
            .btnText {
              font-size: 13px;
            }
            &:hover {
              background: $blue-violet;
              color: $white !important;
              cursor: pointer;
              border-radius: 0 0 10px 10px;
            }
          }
        }
        .add-creditsLoader {
          &.linear-background {
            height: 30px;
            width: 100%;
            border-radius: 0 0 10px 10px;
          }
        }
        .card {
          padding: 15px 12px;
          .headingWrapper {
            display: flex;
            align-items: flex-start;
            width: 100%;
            gap: 15px;
            overflow: hidden;
            min-height: 77px;
            .headerIcon {
              width: 15%;
              display: flex;
              align-items: center;
              justify-content: center;
              scale: 1;
              &.linear-background {
                height: 45px;
                width: 45px;
                border-radius: 50%;
              }
            }
            .heading {
              width: 85%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              overflow-wrap: break-word;
              word-wrap: break-word;
              white-space: normal;
              color: $c-666666;
              gap: 6px;
              h3 {
                font-weight: 500;
                font-size: 17px;
                &.linear-background {
                  display: block;
                  width: 100%;
                  border-radius: 13px;
                  height: 20px;
                }
              }
              p {
                font-size: 12px;
                &.linear-background {
                  display: block;
                  width: 100%;
                  border-radius: 13px;
                  height: 25px;
                }
              }
            }
          }
          .credits {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            margin: 10px 0;
            color: $c-666666;
            span {
              line-height: 26px;
            }
            .creditsCount {
              color: $blue-violet;
              font-size: 18px;
              &.linear-background {
                display: block;
                width: 75px;
                height: 20px;
              }
            }
          }
          .scoreLoader {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            @include flex-align-center;
            font-size: 12px;
            color: $c-3b;
            margin: 0 auto;
            .remaining-highlight {
              color: $blue-violet;
              font-size: 18px;
              font-weight: 600;
            }
            &.linear-background {
              border-radius: 50%;
            }
          }
          .renewal {
            font-size: 12px;
            text-align: left;
            margin: 20px 0 0;
            width: 100%;
            height: 30px;
            &.linear-background {
              display: block;
              width: 100%;
              height: 20px;
            }
          }
        }
      }
    }

    .circle {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: conic-gradient(#007bff 97%, #ddd 3%);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      margin: 20px auto;
    }

    .creditsContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      padding: 20px;

      .card {
        background: transparent;
        border-radius: 8px;
        padding: 20px;
        width: 400px;
        max-width: 400px;
        border: 2px solid $c-ec;

        h3 {
          font-size: 18px;
          margin-bottom: 8px;
          color: $c-3b;
          &.linear-background {
            width: 100px;
          }
        }

        p {
          font-size: 14px;
          color: $c-666666;
          margin-bottom: 12px;
          &.linear-background {
            width: 90%;
          }
        }

        .linearProgessWrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .linearProgessLoaderBody {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 10px 0;
            .limit {
              display: flex;
              justify-content: space-between;
              font-size: 14px;
              margin-bottom: 5px;

              .limitContainer {
                display: flex;
                justify-content: center;
                flex-direction: row;
                align-items: center;
                font-size: 13px;
                .freecallingIcon {
                  display: flex;
                  cursor: pointer;
                  i {
                    font-size: 16px;
                  }
                }
              }
              .customTooltip {
                .tooltipText {
                  color: $white;
                }
                .tooltipBody {
                  background: #393a3d;
                  top: 18px;
                  right: -216px;
                  color: $white;
                  width: 370px;
                }
                .sharpPointer {
                  background-color: #393a3d;
                  top: 9px;
                  right: 0;
                }
              }
            }
          }
        }

        .buttonWrapper {
          display: flex;
          justify-content: flex-start;
          padding: 15px 0;
          width: 40%;
          button {
            background: $white;
            color: $blue-violet;
            border: 1px solid $blue-violet;
            padding: 8px 12px;
            border-radius: 4px;
            cursor: pointer;
            font-size: 14px;
            width: 40%;
          }
        }
      }
    }
    .creditsSeparator {
      border-top: 1px solid $c-ec;
      width: 80%;
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
}
