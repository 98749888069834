.seq-contacts-section {
  position: relative;

  .pagination-section {
    border-bottom: 1px solid $b-c-1;
    border-left: 1px solid $b-c-1;
    border-right: 1px solid $b-c-1;
    @include bor-rad-btm(6px, 6px);
  }

  &.hidePagination {
    .seq-contacts-table-section {
      height: calc(100% - 143px);
    }
  }
  .exportFilterSequenceSection {
    position: relative;
    width: 100%;
    overflow: unset;
    padding: 10px 0;
    display: flex;
    height: 58px;

    .filterExportBtnWrap {
      position: relative;
      @include flex-center;
      justify-content: flex-end;
      .drop-down-menu ul li .listItem .listItemWrap {
        width: fit-content;
        .failedIcon {
          font-size: 20px;
        }
        .reactivateBulkSeqContact {
          @include wt-ht(20px, 18px);
        }
        .resumeBulkSeqContact {
          @include wt-ht(20px, 18px);
        }
      }
      @include addToSequenceButton();
    }
    .tagSelectedSection {
      flex: 1 1;
      display: flex;

      .contactTagList {
        width: calc(100% - 10px);
        margin-left: 10px;
      }
    }

    @include searchInputByKeyword();
  }
  .seq-contacts-table-section {
    height: calc(100% - 196px);
    display: flex;
    flex-direction: column;
    position: relative;
    letter-spacing: 0.3px;
    transition: all 0.3s;
    background: white;
    &.bannerPageShown {
      height: calc(100% - 184px) !important;
      transition: all 0.3s;
    }
    &.bannerSpace {
      height: calc(100% - 131px) !important;
      transition: all 0.3s;
    }
    &.noPageSpace {
      height: calc(100% - 131px) !important;
      transition: all 0.3s;
    }
    &.noExportFilter {
      height: calc(100% - 87px) !important;
    }
    .add-contact-stage {
      text-align: center;
      font-size: 16px;
      letter-spacing: 0.3px;
      color: $c-66;
      height: calc(100% - 48px);
      @include flex-center;
      flex-direction: column;
      justify-content: center;
      @extend %empty-section;
      img {
        @include wt-ht(70px, auto);
      }
      .addContactDropDown {
        position: relative;
        margin-bottom: 40px;
        .dropdown-overlay {
          @include bg-mask(11, transparent);
        }
        .proPlanIcon {
          width: auto;
          height: auto;
        }
        .listItemWrap {
          width: 132px;
        }
      }
      button {
        font-size: 14px;
        padding: 0px 10px;
        cursor: pointer;
        height: 36px;
      }
    }

    .seq-contacts-table-header {
      @include flex-center;
      flex-wrap: nowrap;
      background: white;
      border-bottom: 1px solid $b-c-1;
      @include bor-rad-top(6px, 6px);
      font-size: 14px;
      font-weight: 500;
      color: $c-66;
      width: 100%;
      padding: 5px;
      .contactSelectionCbx {
        width: 30px;
        .checkBoxSection {
          justify-content: center;
        }
      }
      .contact-user-name {
        width: calc(12% - 10px);
      }
      .ctUserEmail {
        width: calc(20% - 20px);
      }
      .contact-user-name,
      .ctUserEmail {
        display: grid;
        padding: 10px;
      }
      .contact-status {
        width: 14%;
        display: grid;
        padding: 10px;
      }
      .contact-stage-insight,
      .contact-insight-date {
        width: 20%;
        display: grid;
        padding: 10px;
      }
      .contact-date {
        white-space: nowrap;
        width: calc(13% - 30px);
        @include flex-center;
        padding: 10px;
      }
      .moreOption {
        width: 30px;
        .emailLimit {
          width: 15px;
        }
      }
    }
    .seq-contacts-table-body {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      border-bottom: 1px solid $b-c-1;
      font-size: 14px;
      position: relative;
      width: max-content;
      width: 100%;
      padding: 5px;
      &:first-child {
        .stage-in {
          .customTooltip {
            .sharpPointer {
              right: -20px !important;
              top: 2px !important;
              border: none;
              transform: rotate(134deg);
            }
            .tooltipBody {
              right: -33px !important;
              top: 18px !important;
            }
          }
        }
      }
      &:not(:first-child) {
        .statusContainer.failed {
          .customTooltip {
            .tooltipBody {
              top: 20px !important;
            }
          }
          .sharpPointer {
            top: 14px !important;
            border-top-right-radius: unset !important;
            border-right: 1px solid #f5f5f5 !important;
            border-top: 1px solid #f5f5f5 !important;
            border-bottom-right-radius: 0px !important;
            border: none;
            z-index: 3 !important;
          }
        }
      }
      &.table-loader {
        height: 53px;
      }

      .linear-background {
        border-radius: 6px;
        width: 90%;
      }

      .contactSelectionCbx {
        @include contactSelectCheckbox();
        width: 30px;
      }
      .ctUserEmail {
        width: calc(20% - 20px);
      }
      .contact-user-name {
        width: calc(12% - 10px);
        word-break: break-all;
      }
      .ctUserEmail,
      .contact-user-name {
        position: relative;
        padding: 10px;
        display: grid;
        text-align: left;
        h6 {
          color: $blue-violet;
          font-size: 14px;
          font-family: $font-regular;
          @include ellipsis();
          font-weight: 500;
          &.linear-background {
            height: 16px;
            width: 70%;
          }
        }
        p {
          @include ellipsis();
          font-size: 14px;
          color: $c-88;
          @include ellipsis();
          &.linear-background {
            height: 14px;
            width: 90%;
          }
        }
      }

      &:first-child .contact-status {
        .customTooltip {
          .tooltipBody {
            top: 22px !important;
            right: -30px !important;
          }
          .sharpPointer {
            top: 16px !important;
            right: 12px !important;
            border-bottom-left-radius: 18px !important;
            border-top-right-radius: unset !important;
          }
        }
      }
      .contact-status {
        width: 14%;
        display: flex;
        padding: 5px;
        align-items: center;
        font-size: 12px;
        position: relative;
        color: $c-53;
        .infoIcon {
          margin-left: 5px;
          font-size: 14px;
          color: $c-8d;
          top: 10px;
          font-weight: 400;
          cursor: pointer;
        }
        .outOfOfficeOverlay {
          position: absolute;
          top: 30px;
          left: 20%;
          box-shadow: 0 0 22.3px 1.7px rgba(59, 59, 59, 0.14);
          border: solid 1px #ebebeb;
          background-color: #ffffff;
          width: 225px;
          z-index: 1;
          border-radius: 10px;
          flex-direction: row;

          .leftContainer {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding: 15px;
            width: 71%;

            .outOfOfficeText {
              color: #1a1a1a;
              font-size: 14px;
              margin-bottom: 10px;
              font-weight: 500;
            }

            .sequenceResumeText {
              font-size: 12px;
              color: #666666;
            }
          }

          img {
            position: absolute;
            bottom: 0;
            width: 75px;
            height: auto;
            right: 5px;
            object-fit: contain;
          }

          .toolTipArrow {
            position: absolute;
            width: 0;
            height: 0;
            border-bottom: 1px solid $white;
            border-right: 1px solid $white;
            background: $white;
            font-size: 0;
            line-height: 0;
            padding: 5px;
            bottom: -4px;
            left: 50%;
            transform: rotate(45deg);
            transition: background 200ms ease;
          }
        }
        .statusContainer {
          @include flex-center;
          border-radius: 5px;
          padding: 2px;
          position: relative;

          .gapFiller {
            position: absolute;
            width: 100%;
            height: 10px;
            top: 100%;
            left: 0;
            background: transparent;
          }

          .customTooltip {
            display: none;
            .tooltipBody.missingSubjectLine {
              width: 200px !important;
            }
          }
          &:hover .customTooltip {
            display: block;
            .tooltipBody {
              background: #393a3d;
              top: -60px;
              right: -186px !important;
              &.autoReplyTooltip {
                right: -92px !important;
                width: unset;
                min-width: 185px !important;
              }
              &.inboxUnavailable {
                min-width: 426px;
              }
              .tooltipText {
                color: $white;
                a {
                  font-weight: bolder;
                }
              }
            }
            .sharpPointer {
              background: #393a3d;
              border-top-right-radius: 18px;
              border-right: 1px solid #393a3d;
              border-top: 1px solid #393a3d;
              top: -24px;
              right: 12px;
            }
            .linkHighLight {
              color: $c-blue;
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .status-name {
            color: $c-3b !important;
            text-transform: capitalize !important;
          }

          &.paused:hover {
            background-color: rgba(255, 145, 91, 0.2);
          }
          &.active:hover {
            background-color: rgba(57, 83, 251, 0.2);
          }
          &.replied:hover {
            background-color: rgba(106, 194, 89, 0.2);
          }
          &.failed:hover {
            background-color: rgba(255, 0, 0, 0.2);
          }
          &.auto_reply {
            padding: 4px;
            color: #3b3b3b;
            &:hover {
              background-color: #f4f4f6;
              cursor: pointer;
            }
          }
          &.failed {
            padding: 5px;
          }
          &.linkedinReply {
            padding: 4px;
            cursor: pointer;
            .status-name {
              color: $c-3b !important;
            }
            &:hover {
              background-color: #f5f0f0;
              .status-name {
                color: #0a66c2 !important;
              }
            }
            svg {
              @include wt-ht(10px, 10px);
            }
          }
          &.paused,
          &.active,
          &.failed,
          &.replied {
            cursor: pointer;
          }
          .status-name {
            @include flex-center;
            position: relative;
            &.cursor {
              cursor: pointer;
            }
            .customTooltip {
              display: none;
              &.missingSubjectLine {
                width: 200px !important;
              }
            }
            &:hover .customTooltip {
              display: block;
              .tooltipBody {
                background: #393a3d;
                top: -62px;
                right: -186px !important;
                width: unset;
                min-width: 426px;
                &.inboxUnavailable {
                  min-width: 426px;
                }
                &.missingTokenVariable {
                  right: -120px !important;
                  min-width: 280px;
                }
                .tooltipText {
                  color: $white;
                  a {
                    font-weight: bolder;
                  }
                }
              }
              .sharpPointer {
                background: #393a3d;
                border-top-right-radius: 18px;
                border-right: 1px solid #393a3d;
                border-top: 1px solid #393a3d;
                top: -24px;
                right: 12px;
              }
              .linkHighLight {
                color: $blue-violet;
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            &:hover .autoReplyTooltip {
              &.tooltipBody {
                min-width: 150px;
                width: fit-content;
                right: -60px !important;
              }
              &.sharpPointer {
                right: 26px !important;
              }
            }
            &.paused_ooto {
              cursor: pointer;
            }
            .autoReplyStatusIcon {
              svg {
                @include wt-ht(16px, 16px);
              }
            }
            i {
              font-size: 14px;
              margin-bottom: 2px;
            }
            &.color-active i {
              color: $blue-violet;
            }
            &.color-paused i {
              color: $color-orange;
            }
            &.color-complete i {
              color: $color-green;
            }
            &.color-meeting-booked i {
              color: #272056;
            }
            &.color-default i {
              color: $c-78;
            }
            &.color-hold i {
              color: $red;
            }
            &.color-pending i {
              color: #f7ae05;
            }
            &.color-failed i {
              color: $red;
            }
          }
          .change-status {
            position: relative;

            .icon-arrow {
              position: relative;
              font-size: 12px;
              cursor: pointer;
              padding: 5px 0 5px 5px;
              margin-left: 5px;
              border-radius: 50%;
              transition: all 0.2s ease-in;
              vertical-align: middle;
            }
            .status-dropdown {
              @include list-style-zero();
              position: absolute;
              padding: 10px;
              top: 24px;
              right: 0px;
              z-index: 5;
              border-radius: 8px;
              box-shadow: 0 0 25.1px 1.9px rgba(59, 59, 59, 0.07);
              border: solid 1px #dfdfdf;
              background-color: #ffffff;
              min-width: 120px;
              .status-dropdown-item {
                letter-spacing: 0.3px;
                margin: 0;
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 14px;
                border-radius: 6px;
                transition: all 200ms ease;
                cursor: pointer;
                i {
                  margin-right: 10px;
                  font-size: 14px;
                  line-height: 15px;
                }
                &.color-active {
                  color: $blue-violet;
                }
                &.color-paused {
                  color: $color-orange;
                }
                &:hover {
                  transition: all 200ms ease;
                  background: #f5f5f5;
                  color: $c-33;
                }

                span {
                  font-size: 14px;
                  line-height: normal;
                }
              }
            }
          }
          .linear-background {
            height: 14px;
            width: 90%;
          }
          .linkedInReplyVisibility {
            svg {
              width: 20px;
              height: 20px;
            }
          }
          .replyVisibility {
            position: relative;
            margin-left: 4px;
            padding: 4px;
          }
        }
      }
      .contact-stage-insight {
        width: 20%;
        display: grid;
        padding: 0 10px;
        &.insight-loader {
          @extend %email-insight-loader;
          padding: 0 10px;
          margin: 0;
        }
      }
      .contact-insight-date {
        width: 20%;
        cursor: pointer;
        display: grid;
        padding: 0 10px;
        .stage-in {
          position: relative;
          @include flex-center;
          border-radius: 3px;
          justify-content: center;
          font-size: 13px;
          padding: 5px 10px;
          background-color: $c-f5;
          width: fit-content;
          i {
            font-size: 13px;
          }
          &.no-stage {
            background: $white;
          }
          .customTooltip {
            background: #393a3d;
            .sharpPointer {
              background: #393a3d;
              border: 1px solid transparent;
              padding: 5px;
              border-radius: unset;
              &.topCenter {
                right: 50px !important;
                top: -24px !important;
              }
              &.bottomCenter {
                right: 50px !important;
                top: 12px !important;
              }
            }
            .tooltipBody {
              width: fit-content;
              right: -10px;
              white-space: nowrap;
              background: #393a3d;
              right: -33px;
              top: -76px;
              .tooltipText {
                color: $white;
                padding: 10px;
              }
              &.topCenter {
                top: -56px;
              }
              &.bottomCenter {
                top: 18px;
              }
            }
          }
        }
        .linear-background {
          height: 24px;
          width: 90%;
        }
      }
      .contact-date {
        position: relative;
        @include flex-center;
        width: calc(13% - 30px);
        padding: 10px;
        font-size: 13px;
        color: $c-66;
      }
      .moreOption {
        position: relative;
        @include flex-center;
        width: 30px;
        .emailLimit {
          width: 15px;
        }
        .checkCircleOutlineIcon,
        .failedIcon,
        .skipNextIcon {
          font-size: 18px;
          @include wt-ht(18px, 18px);
          color: $c-66;
        }
        .reactivateIcon {
          @include wt-ht(18px, 18px);
        }
        i {
          position: relative;
          cursor: pointer;
          padding: 5px;
          border-radius: 35px;
          color: $c-66;
          font-size: 20px;
          &:hover {
            background: $c-f5;
            color: $c-22;
          }
        }
        .drop-down-menu {
          bottom: 0px;
          right: 20px;
        }
        .dropdownOverlay {
          @include bg-mask(1, transparent);
        }
      }
    }
  }
}

.contactDetailsCont {
  .seq-contacts-table-header {
    border-top: 1px solid $b-c-1;
    padding: 5px 20px;
    background: $c-fa;
  }
  .seq-contacts-table-body {
    padding: 5px 20px;
    .ctUserEmail {
      span {
        @extend %relBlock;
      }
    }
    h6 {
      margin-bottom: 5px;
    }
  }
}
