#billingSection {
  position: relative;
  display: block;
  margin: 25px;
  overflow: auto;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  @media (max-width: 1080px) {
    width: 100%;
  }

  .contentSection {
    position: relative;
    margin-top: 20px;
    width: 100%;
    .dataContainer {
      display: block;
      width: 100%;

      @media (max-width: 767px) {
        grid-template-columns: 100%;
      }
      justify-content: space-between;
      row-gap: 10px;
      position: relative;
    }
    .inputWrapper {
      @extend %inputWrapper;
      &.additionalInboxesUsed {
        margin-bottom: 12px !important;
      }

      .inputCont {
        &.readOnlyInput {
          .inputLabel {
            font-size: 13px;
          }
        }
      }
    }
  }

  .buttonSection {
    .btn {
      @extend %secondaryButton;
    }
  }

  .cancelSubBtn {
    width: 150px;
    margin: 0;
    background-color: $blue-violet !important;
  }

  .noActivePlan {
    @include flex-center();
    flex-direction: column;

    .noActiveText {
      margin-bottom: 15px;
      font-size: 15px;
    }
  }
}
