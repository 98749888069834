.section {
  position: relative;
  z-index: 1;
  overflow: hidden;
  gap: 20px;
  padding: 20px;

  .mainContainer {
    background: #3953fb;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    background-image: url("../../app/Pages/Dashboard/pricing/PricingV2/images/Testimonials/bg.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    position: relative;
    background-blend-mode: multiply;

    .backgroundPattern {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 35%;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .userQuotes {
    color: white;
    text-align: left;
    line-height: 1.6;
    font-size: 18px;
    flex: 1;
    margin: 0 20px;
    padding: 40px;
  }

  .userDetails {
    flex: 0 0 auto;
    z-index: 1;
    margin-right: 20px;
    margin-top: -30px;
    padding-top: 40px;

    .samGrimleyImg {
      position: relative;
      max-width: 100%;
      height: auto;
      display: block;
    }
  }
}
