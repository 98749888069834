%successGreenTick {
  .iconShadow {
    position: relative;
    i {
      font-size: 48px;
      &.success {
        color: $green;
      }
      &.failure {
        color: $red;
      }
    }
    .shadow {
      position: relative;
      display: block;
      border-radius: 50%;
      padding: 2px;
      width: 48px;
      margin: 0 auto;
      background: $c-f5;
    }
  }
}
%headerNav {
  nav {
    position: relative;
    z-index: 1;
    padding-top: 10px;
    @media (max-width: 992px) {
      padding-bottom: 10px;
    }
    .headerMenuWrapper {
      position: relative;
      @include flex-center;
      @extend %relBlock;
      justify-content: space-between;
      .logo-title {
        svg {
          height: 36px;
          fill: white;
          path {
            fill: white;
          }
        }
      }
      .menuWrapper {
        position: relative;
        @include list-style-zero;
        @include flex-center;
        .menuIcon {
          display: none;
          @media (max-width: 576px) {
            margin-right: 10px;
            display: block;
          }
          position: relative;
          width: fit-content;
          i {
            padding: 5px;
            border-radius: 35px;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              background: rgb(76, 121, 157);
              transition: all 0.3s;
            }
          }
        }
        .menuListDropdown {
          position: absolute;
          @include list-style-zero;
          display: none;
          z-index: 1000;
          background: white;
          flex-flow: column;
          width: 166px;
          right: 10px;
          top: 30px;
          padding: 10px;
          border-radius: 10px;
          box-shadow: -1px -1px 6px 1px #a0aab3;
          @media (max-width: 576px) {
            display: flex;
          }
          .menuItems {
            @extend %relBlock;
            width: 100%;
            border-radius: 5px;
            padding: 5px;
            cursor: pointer;
            a {
              color: $c-66;
              text-decoration: none;
              font-size: 16px;
            }
            &:hover {
              background: $c-f5;
              a {
                color: $c-3b;
                text-decoration: underline;
              }
            }
          }
        }
        .menuList {
          position: relative;
          display: block;
          @include list-style-zero;
          @include flex-center;
          justify-content: flex-end;
          margin-right: 80px;
          @media (max-width: 576px) {
            margin-right: 10px;
            display: none;
          }
          @media (min-width: 576px) and (max-width: 767px) {
            margin-right: 40px;
          }
          .menuItems {
            margin-left: 10px;
            cursor: pointer;
            @media (max-width: 480px) {
              margin-left: 10px;
            }
            &.requestDemo {
              position: relative;
              a {
                border: 1px solid white;
              }
            }
            a {
              color: white;
              padding: 10px 15px;
              border: 1px solid transparent;
              font-size: 14px;
              @media (max-width: 480px) {
                font-size: 14px;
              }
              font-weight: bold;
              letter-spacing: 1px;
              text-decoration: none;
              &:hover {
                border: 1px solid white;
                background: white;
                color: $blue-violet;
                border-radius: 3px;
              }
            }
          }
        }
      }
    }
  }
}

#userRegister {
  font-family: $font-regular;
  position: relative;
  background: $dark-blue-violet;
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  display: block;
  color: $white;
  letter-spacing: 0.3px;
  line-height: 1.5;
  .container {
    position: relative;
    height: 100%;
    @extend %headerNav;
    .register-body {
      position: relative;
      z-index: 1;
      display: grid;
      grid-template-columns: 50% 50%;
      justify-content: center;
      align-items: center;
      height: calc(100% - 178px);
      @media (max-width: 992px) {
        grid-template-columns: 100%;
        margin: 0 auto;
        height: calc(100% - 98px);
      }
      @media (min-width: 0) and (max-width: 640px) {
        width: 280px;
        height: fit-content;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        margin-top: 30px;
        z-index: 0;
      }
      &.center-grid {
        grid-template-columns: 100%;
      }
      &.teamBg {
        border-radius: 10px;
        overflow: auto;
        background: $white;
        margin-top: 20px;
        padding: 20px;
        animation: fadeIn 0.3s linear;
      }
      &.activateMountLoader {
        height: calc(100% - 178px);
      }
      &.teamMountLoader,
      &.activateMountLoader {
        background: transparent;
        grid-template-columns: 100% 129px;
      }
      &.teamMountLoader {
        background: transparent;
        grid-template-columns: 100% 129px;
      }
      .teamInviteSection {
        position: relative;
        @include flex-center();
        color: $c-66;
      }
      .teamContentSection {
        position: relative;
        width: 90%;
        text-align: center;
        .invitedName,
        .joinText {
          display: block;
          font-size: 20px;
          font-weight: 600;
          letter-spacing: 0.3px;
          line-height: 1.4;
          color: $c-66;
          min-height: 20px;
        }
        .inviteImg {
          margin-top: 40px;
          width: 100%;
          img {
            max-width: 60%;
            margin: 0 auto;
          }
        }
      }
      .contentBody {
        position: relative;
        @include flex-direction(column);
        @media (min-width: 0) and (max-width: 992px) {
          display: none;
        }
        .titleImgWrap {
          @extend %relBlock;
          h1 {
            font-family: $font-semi-bold;
            width: fit-content;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            position: relative;
            font-size: 72px;
            line-height: 1.5;
            z-index: 0;
            letter-spacing: 0.3px;
            @media (min-width: 991px) and (max-width: 1080px) {
              font-size: 48px;
            }
            @media (min-width: 1080px) and (max-width: 1200px) {
              font-size: 60px;
            }
            &.pluginClass {
              font-size: 44px;
              line-height: 1.5;
              z-index: 0;
              letter-spacing: 0.3px;
              @media (min-width: 991px) and (max-width: 1200px) {
                font-size: 36px;
              }
            }
          }
          img {
            position: absolute;
            top: 0;
            z-index: -1;
            right: -40px;
            width: 80%;
            &.pluginClass {
              position: absolute;
              bottom: -20px;
              z-index: -1;
              left: -40px;
              top: unset;
              right: unset;
              width: 60%;
            }
          }
        }
        .arrowRight {
          text-align: right;
          position: relative;
          width: 100%;
          img {
            position: absolute;
            right: 0;
          }
        }
      }
      .register-panel {
        display: flex;
        align-items: center;
        justify-content: center;
        .register-form {
          position: relative;
          -webkit-animation: slideFromRightToLeft 0.5s;
          animation: slideFromRightToLeft 0.5s;
          #login,
          #signup,
          #forgotPassword,
          #resetPassword,
          #activateUser {
            transform: translateY(0);
            transition: all 200ms linear;
            width: 400px;
            background: $white;
            color: $black;
            border-radius: 20px;
            padding: 20px 50px;
            @media (min-width: 0) and (max-width: 540px) {
              width: 280px;
              padding: 20px;
              border-radius: 6px;
            }
            @media (min-width: 540px) and (max-width: 767px) {
              width: 360px;
              padding: 20px 40px;
              border-radius: 10px;
            }
            @media (min-width: 768px) and (max-width: 992px) {
              border-radius: 10px;
            }
            h3 {
              font-family: $font-semi-bold;
              color: $c-3b;
              font-size: 28px;
              @media (min-width: 0) and (max-width: 767px) {
                font-size: 22px;
              }
            }
            h6 {
              font-size: 16px;
              color: $c-66;
              margin-bottom: 30px;
            }
            .btm-m-10 {
              bottom: -10px;
            }
            .forgot-desc-text {
              font-size: 14px;
              color: $c-66;
              margin-top: 15px;
            }
            .activateSuccess {
              text-align: center;
              .activateBoldText {
                margin: 15px 30px;
                font-size: 24px;
                font-weight: 600;
                line-height: 1.4;
                letter-spacing: 0.3px;
                text-align: center;
                color: $c-22;
              }
              .activateSpinner {
                @extend %spinnerIcon;
              }
              .activateInfoText {
                font-size: 14px;
                color: $c-78;
                margin-bottom: 10px;
              }
            }
            .forgot-link-section,
            .activateSuccess {
              @extend %successGreenTick;
              position: relative;
              display: block;
              text-align: center;
              .forgot-link-text,
              .activateText {
                color: $c-44;
                text-align: center;
                margin: 25px 0;
              }
              .forgot-link-btn,
              .activateButton {
                text-align: center;
                margin-bottom: 20px;
                a {
                  display: block;
                  text-decoration: none;
                  cursor: pointer;
                  width: 120px;
                  height: 42px;
                  padding: 10px 0;
                  margin: 0 auto;
                  font-weight: 600;
                  color: $white;
                  background: $light-green;
                  font-size: 16px;
                  border-radius: 5px;
                  transition: all 0.3s ease;
                  &:hover {
                    background: $green;
                    transition: all 0.3s ease;
                    box-shadow: 0 0 4px 2px $green-shadow-lg;
                  }
                }
              }
            }
            .form-button {
              width: 100%;
              padding: 10px 0;
              text-align: center;
              button {
                width: 100%;
                &.fill-transistion {
                  z-index: 1;
                  position: fixed;
                  user-select: none;
                  pointer-events: none;
                }
              }
            }
            form,
            #signupForm,
            #loginForm {
              margin-top: 20px;
              .teamEmailPopulate {
                position: relative;
                margin-bottom: 20px;
                display: block;
                label {
                  position: relative;
                  display: block;
                  font-size: 12px;
                  color: $c-66;
                }
                .email {
                  padding: 2px 0;
                  font-size: 16px;
                  color: $blue-violet;
                }
                .errMsgText {
                  color: red;
                  font-size: 12px;
                  white-space: nowrap;
                  display: block;
                  left: 0;
                  bottom: -15px;
                }
              }
              input,
              button {
                width: 100%;
                letter-spacing: 0.3px;
              }
            }
            .info-text-forgot {
              text-align: center;
              font-size: 14px;
              a {
                color: $c-78;
                text-decoration: none;
              }
            }
            .info-text {
              text-align: center;
              color: $c-78;
              font-size: 14px;
              a {
                color: $blue-violet;
                text-decoration: none;
                font-weight: bold;
              }
            }
            .space-bar {
              margin-top: 30px;
            }
          }
          #resetPassword {
            padding: 35px 40px;
            h3 {
              margin-bottom: 20px;
            }
          }
          #forgotPasswordForm {
            margin-top: 20px;
          }
        }
      }
    }
  }
  .footer {
    position: relative;
    margin-top: 20px;
    width: 100%;
    z-index: 0;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
    background: transparent;
    @media (max-width: 992px) {
      position: relative;
      font-size: 13px;
    }
    .footer-block {
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: 50% 50%;
      &.teamFooter {
        grid-template-columns: 100%;
      }
      @media (min-width: 0) and (max-width: 992px) {
        grid-template-columns: 100%;
      }
      @media (min-width: 0) and (max-width: 992px) {
        .empty-block {
          display: none;
        }
      }
      &.center-grid {
        grid-template-columns: 100%;
      }
      .algin-center {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      svg {
        margin: 0 7px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .bg-wave-line {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 0;
  }
}

@keyframes dots {
  0%,
  20% {
    color: red;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: red;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 red, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 red, 0.5em 0 0 red;
  }
}
.transition {
  transition: 0.3s cubic-bezier(0.3, 0, 0, 1.3);
}

@keyframes slideFromRightToLeft {
  from {
    margin-right: -50%;
    opacity: 0;
  }
  to {
    margin-right: 0px;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

%btnClass {
  cursor: pointer;
  min-width: 200px;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 30px;
}
.overlayExpMask {
  @include bg-mask(1, rgba(0, 0, 0, 0.57));
}
.dealify-err.err-msg-text {
  top: 50px;
}

.dealifyPopupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  @include wt-ht(100%, 100%);
  @include flex-center;
  justify-content: center;
  z-index: 21;
  .dealifyPopupCard {
    background: white;
    border-radius: 10px;
    z-index: 23;
    position: relative;
    padding: 50px;
    @include flex-center;
    flex-direction: column;
    .closeIcon {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 24px;
      color: $c-847c7c;
      cursor: pointer;
      padding: 5px;
      border-radius: 35px;
      transition: all 0.3s ease;
      &:hover {
        color: black;
        background: $c-f5;
        transition: all 0.3s ease;
      }
    }
    .imageWrapper {
      position: relative;
      margin-bottom: 56px;
      text-align: center;

      .mailOpenerImage {
        max-width: 80%;
      }
      .ellipsisImage {
        position: absolute;
        top: 96px;
        left: -29px;
        z-index: -1;
      }
    }
    .dealifyContent {
      text-align: center;
      width: 430px;
      font-size: 18px;
      color: $c-666666;
      margin-bottom: 40px;
      a {
        text-decoration: none;
        &:hover {
          color: -webkit-link;
        }
      }
    }
    .dealifyButton {
      width: 142px;
      height: 46px;
      background-color: $c-106aff;
      justify-content: space-around;
      font-size: 18px;
      i {
        transform: rotate(180deg);
      }
    }
  }
  .overlayDealifyMask {
    @include bg-mask(22, rgba(0, 0, 0, 0.57));
  }
}

.pluginSuccessPage {
  background: #e9f1fe;
  @extend %relBlock;
  height: 100%;
  overflow: auto;
  .pinPluginPopupWrapper {
    position: fixed;
    background-color: $c-ffffff;
    color: $white;
    padding: 25px 16px;
    border-radius: 10px;
    z-index: 13;
    top: -97px;
    right: -53px;
    scale: 0.7;
    .chrome {
      @include flex-center();
      justify-content: center;
      width: 100%;
    }
    .chromeDescriptionWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .chromeDescription {
        padding: 20px;
        text-align: center;
        font-size: 18px;
        width: 400px;
        max-width: 450px;
        .descpPoints {
          color: $c-333333;
          .chromeExtensionIcon {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
    .greenTickImageWrapper {
      @include flex-center();
      justify-content: center;
      width: 100%;
      .greenTick {
        @include wt-ht(30px, 30px);
        box-shadow: -1px 0px 13px 11px rgb(53, 81, 45);
        border-radius: 50%;
        margin-bottom: 16px;
      }
    }
    .pluginPinImg {
      @include wt-ht(415px, 320px);
    }
    .sharpPointer {
      position: absolute;
      border-top-right-radius: 5px;
      padding: 8px;
      border-right: 1px solid $c-f5;
      border-top: 1px solid $c-f5;
      z-index: -1;
      top: -9px;
      right: 55px;
      transform: rotate(-45deg);
      background: $c-ffffff;
    }
    .textContent {
      margin-bottom: 16px;
      text-align: center;
      width: 100%;
      font-size: 16px;
      line-height: 1.5;
      .adaptInstalledText {
        font-weight: 700;
        margin-bottom: 8px;
      }
      svg {
        width: 17px;
        height: 17px;
        margin: 0 6px;
        fill: $white;
      }
    }
    .imageWrapper {
      position: relative;
      padding: 7px 25px;
      height: fit-content;
    }
    .buttonWrapper {
      @include flex-center();
      justify-content: center;
    }
    .pluginPinOkButton {
      @include flex-center();
      justify-content: space-between;
      text-align: center;
      border: 0;
      background: #476eff;
      color: $white;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      margin-top: 16px;
      width: 130px;
      height: 50px;
      border-radius: 10px;
      padding: 16px;
      svg {
        width: 20px;
        height: 15px;
        path {
          fill: $white;
        }
      }
    }
  }
  nav.pluginNavHeader {
    height: 64px;
    @include flex-center;
    justify-content: space-between;
    svg {
      height: 34px;
    }
    .wrapHeaderLogo {
      @include flex-center;
      gap: 6px;
      max-width: 200px;
      svg {
        width: unset !important;
      }
    }
    .menuList {
      a {
        letter-spacing: 0.3px;
        font-weight: 400;
        color: #2e2e36;
        text-transform: capitalize;
        text-decoration: none;
        border-radius: 20px;
        padding: 5px 20px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          font-weight: 500;
          background: $c-f5;
          color: black;
        }
      }
    }
  }
  .pluginSuccessBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    .succesImage {
      .succesImageContainer {
        scale: 0.6;
        transform: translateY(35px);
      }
    }

    .textDesc {
      font-size: 20px;
      text-align: center;
      margin: 20px 0 20px;
      line-height: 1.5;
    }

    .pluginDescriptionWrapper {
      width: 80%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      list-style-position: inside;
      list-style-type: disc;
      padding: 0;
      margin: 0 auto;
      gap: 10px;

      .pluginDescription {
        font-size: 16px;
        text-align: left;
        line-height: 1.6;
      }
    }

    .signupPluginContent {
      @extend %relBlock;
      text-align: center;
      .setUpMyAccount {
        @extend %btnClass;
      }
      .liveFeedBrowserImg {
        margin: 0 auto;
        max-width: 100%;
      }
      animation: fadeIn 0.3s linear;
    }
    .loginPluginContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @extend %relBlock;
      .goToUrlPathFromPlugin {
        @extend %btnClass;
        margin-top: 30px;
      }
      animation: fadeIn 0.3s linear;
      .rewardsWrapper {
        margin-top: 20px;
        background-color: $c-ffffff;
        border: 1px solid $c-d9d9d9;
        .rewards {
          padding: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          .rewardDescription {
          }
        }
      }
      .navTodashboardUrl {
        @extend %relBlock;
        padding: 20px;
        display: flex;
        flex-direction: row;
        gap: 15px;
        .dashboardButtonWrapper {
          button {
            width: 219px;
            letter-spacing: 0.6px;
            font-size: 16px;
            cursor: pointer;
            background-color: $white;
            border: 1px solid $blue-violet;
            color: $blue-violet;
            height: 50px;
          }
        }
        .linkedinButtonWrapper {
          button {
            width: 219px;
            letter-spacing: 0.6px;
            font-size: 16px;
            cursor: pointer;
            background-color: $blue-violet;
            border: 1px solid $white;
            color: $white;
            height: 50px;
            gap: 10px;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.eu-checbox-section {
  position: relative;
  display: block;
  z-index: 0;
  padding: 5px 0;
  .err-eu-check {
    position: relative;
    display: block;
    font-size: 12px;
    line-height: 1.3;
    color: red;
  }
}

.teamThanksSection {
  position: relative;
  color: $c-66;
  text-align: center;
  @extend %successGreenTick;
  .thankYouMsgText {
    margin: 15px 0px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0.3px;
    text-align: center;
    color: $c-22;
    word-break: break-all;
  }
  .contentBody {
    margin: 25px 30px 15px;
    letter-spacing: 0.3px;
    text-align: center;
    color: $c-22;
    h4 {
      font-size: 32px;
      font-weight: 600;
      line-height: 1.4;
      margin-bottom: 5px;
    }
    p {
      color: $c-66;
      font-weight: 400;
    }
    .navToPluginUrl {
      @extend %relBlock;
      margin-top: 40px;
      button {
        width: 250px;
        letter-spacing: 0.6px;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
  .spinnerLoader {
    @extend %spinnerIcon;
  }
  .infoText {
    font-size: 14px;
    color: $c-78;
  }
}

.wrap-input-form {
  display: flex;
  column-gap: 10px;
}
.input-form .err-msg-text {
  bottom: 5px;
}

.registerBody {
  position: relative;
  background: #f7f7f7;
  display: block;
  z-index: 0;
}

.horizontal-line {
  background: #ff6057;
  border: none;
  height: 4px;
  border-radius: 3px;
  margin: 5px auto 10px;
  @media (min-width: 768px) {
    margin: 10px auto 15px;
  }
  @media (min-width: 992px) {
    margin: 10px auto 20px;
  }
}

.sm-width {
  width: 86px;
}

.md-width {
  width: 150px;
}

.line-left {
  text-align: left;
  margin-left: 0;
  margin-right: 0;
}

@-webkit-keyframes slideInFromTop {
  0% {
    height: 0;
    opacity: 0;
    -webkit-transform: translateZ(-200px) translateY(-200px);
    transform: translateZ(-200px) translateY(-200px);
  }
  50% {
    height: 100px;
    opacity: 0.5;
    -webkit-transform: translateZ(-100px) translateY(-100px);
    transform: translateZ(-100px) translateY(-100px);
  }
  100% {
    height: auto;
    opacity: 1;
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
  }
}

@keyframes slideInFromTop {
  from {
    z-index: -1;
    height: 0;
    opacity: 0;
    -webkit-transform: translateZ(-200px) translateY(-200px);
    transform: translateZ(-200px) translateY(-200px);
  }
  to {
    z-index: 0;
    height: auto;
    opacity: 1;
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
  }
}

.termsAndPolicyText {
  font-size: 12px;
  text-align: center;
  color: $c-88;
  margin-top: 5px;
  a {
    text-decoration: none;
    color: #465fff;
  }
}

.contentViewBody {
  position: relative;
  display: grid;
  height: calc(100% - 64px);
  justify-content: center;
  grid-template-columns: 100%;
  overflow: auto;

  @media (min-width: 768px) {
    height: calc(100% - 74px);
    grid-template-columns: repeat(2, 50%);
  }
  .leftRegisterContent,
  .teamContentSection {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: max-content;
    padding: 40px 5%;
    @media (min-width: 768px) {
      padding: 20px 9%;
      height: calc(100vh - 74px);
    }
    h1 {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.5;
      text-align: left;
      color: #333;
      @media (min-width: 768px) {
        font-size: 24px;
      }
      @media (min-width: 992px) {
        font-size: 28px;
      }
      @media (min-width: 1200px) {
        font-size: 32px;
      }
    }
    .keylist {
      list-style: none;
      padding-left: 0;

      li {
        @include flex-center;
        &:not(:last-child) {
          margin-bottom: 5px;
          @media (min-width: 768px) {
            margin-bottom: 10px;
          }
        }
        font-size: 14px;
        @media (min-width: 768px) {
          font-size: 16px;
        }
        font-weight: 300;
        color: #333;
        &::before {
          font-family: "Material Icons";
          content: "check";
          color: $white;
          font-size: 14px;
          @media (min-width: 768px) {
            font-size: 16px;
          }
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          margin-right: 10px;
          transition: all ease 0.3s;
          width: 20px;
          height: 20px;
          padding: 5px;
          background-color: $c-36d593;
          border-radius: 50%;
        }
      }
    }
    .plans {
      display: flex;
      flex-direction: column;

      width: 100%;
      .planItemHeading {
        font-size: 12px;
        color: $c-1d2026;
        padding: 3px 0 7px;
        font-weight: 600;
      }
      .planItems {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
        align-items: center;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 5px 0;
        scrollbar-width: thin;
        scrollbar-color: $c-e0 transparent;
        transition: overflow-x 0.3s ease-in-out;

        .planItemWrapper {
          background-color: $c-f7;
          border: 1px solid $c-e0;
          border-radius: 10px;
          min-height: 80px;
          max-height: 115px;
          .items {
            display: flex;
            flex-direction: column;
            padding: 13px 5px;
            width: 160px;
            .planIcon {
              scale: 0.7;
              max-height: 38px;
              display: flex;
              align-items: center;
              margin-left: -11px;
            }
            .itemDescription {
              font-size: 12px;
              padding: 15px 8px;
            }
          }
        }
      }
    }
    .clientSection {
      position: relative;
      display: block;
      h4 {
        font-size: 14px;
        @media (min-width: 768px) {
          font-size: 16px;
        }
        text-align: center;
        padding: 20px 2px 10px;
        font-weight: 600;
        line-height: 1.6;
        color: $c-33;
      }
      .clientsLogo {
        position: relative;
        border: solid 1px $c-ed;
        @include flex-center;
        justify-content: space-evenly;
        border-radius: 35px;
        padding: 15px;
        width: fit-content;
        margin: 0 auto;
        flex-wrap: wrap;
        row-gap: 20px;
        @media (min-width: 992px) {
          padding: 20px 40px;
          justify-content: space-evenly;
        }
        img {
          height: 16px;
          @media (min-width: 576px) {
            height: 18px;
          }
          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }
  }
  .teamContentSection {
    background: #465fff;
    overflow-y: auto;
    h1 {
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 20px;
      color: white;
      line-height: 1.5;
      @media (min-width: 768px) {
        font-size: 24px;
        margin-bottom: 30px;
      }
      @media (min-width: 992px) {
        font-size: 32px;
      }
      b {
        text-transform: capitalize;
      }
    }
    .teamDescText {
      margin-bottom: 20px;
      color: $c-88;
      font-size: 14px;
      color: white;
      @media (min-width: 768px) {
        font-size: 16px;
        margin-bottom: 40px;
      }
    }
    .inviteImg {
      text-align: center;
      img {
        width: 70%;
        margin: 0 auto;
      }
    }
  }
  .registerBox {
    position: relative;
    background: $c-f7;
    @include flex-center;
    justify-content: center;
    height: max-content;
    @media (min-width: 768px) {
      height: calc(100vh - 74px);
    }
    &.whiteBox {
      background: white;
      .formInputBox {
        box-shadow: none !important;
      }
    }
    .registerForm {
      padding: 20px 0;
      position: relative;
      display: block;
      width: 100%;
      overflow-y: auto;
      max-height: 100%;
      transition: overflow-y 0.3s ease-in-out;
      .formInputBox {
        position: relative;
        display: block;
        height: 100%;
        overflow-y: auto;
        background: white;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.19);
        width: 270px;
        border-radius: 10px;
        padding: 20px;
        margin: 0 auto;
        .verificationTextWrapper {
          .confirmationText {
            font-size: 18px;
            line-height: 1.8;
            color: $c-66;
            .emailLink {
              text-decoration: none;
              font-weight: 500;
            }
          }
          .resendBtnContainer {
            padding-top: 30px;
            color: $c-66;
            text-align: center;
            .message {
              font-size: 13px;
              color: $c-66;
              padding-bottom: 0;
            }
          }
        }
        @media (min-width: 576px) {
          width: 360px;
          padding: 30px;
        }
        @media (min-width: 768ppx) {
          width: 400px;
          padding: 40px;
        }
        @media (min-width: 992px) {
          width: 440px;
          padding: 50px;
        }
        @media (min-width: 1200px) {
          width: 500px;
        }
        h3 {
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0.45px;
          text-align: left;
          color: $c-33;
          margin-bottom: 20px;
          @media (min-width: 768px) {
            font-size: 24px;
          }
        }
        .collapsedInputBox {
          position: relative;
          display: block;
          background: white;
          transition: overflow-y 0.5s ease-in-out;
          &.expandInputBox {
            animation: slideInFromTop 0.5s linear;
          }
        }
        .descText {
          font-size: 14px;
          text-align: left;
          color: $c-88;
          margin-bottom: 20px;
          line-height: 1.3;
          a {
            cursor: unset;
            color: #465fff;
            text-decoration: none;
            &:active {
              color: #465fff;
            }
          }
          @media (min-width: 768px) {
            font-size: 16px;
          }
        }
        .inputBoxSection {
          position: relative;
          background: white;
          &:focus-within label {
            color: #465fff;
          }
          margin-bottom: 24px;
          & > * {
            display: block;
          }
          label {
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.22px;
            text-align: left;
            color: $c-52;
            margin-bottom: 6px;
          }
          .wrapInputIcon,
          .inputBox {
            position: relative;
            @include flex-center;
            width: 100%;
            border: 2px solid $c-db;
            padding: 3px 8px;
            border-radius: 8px;
            &:focus-within {
              border: solid 2px #cbd2ff;
            }
            background-color: #fff;
            height: 32px;
            @media (min-width: 768px) {
              height: 36px;
              padding: 5px 10px;
            }
            @media (min-width: 768px) {
              height: 42px;
            }
            .material-icons-outlined {
              cursor: pointer;
              color: #cbd2ff;
              margin-left: 5px;
              font-size: 16px;
              @media (min-width: 768px) {
                font-size: 18px;
              }
              @media (min-width: 768px) {
                font-size: 20px;
              }
            }
            input {
              position: relative;
              display: block;
              font-size: 16px;
              width: 100%;
              outline: none;
              border: none;
              font-size: 14px;
              border-radius: 8px;
              background-color: #fff;
              letter-spacing: 0.3px;
              color: $c-55;
              transition: all 0.2s linear;
              &::placeholder {
                font-size: 14px;
                color: $c-c4;
              }
            }
          }
        }
        .forgotPasswordLink a {
          font-size: 14px;
          text-align: left;
          color: $c-88;
          text-decoration: none;
          &:hover {
            color: black;
            text-decoration: underline;
          }
        }
        .errMsgText {
          font-size: 12px;
          color: red;
          line-height: 1;
          &.btnErrText {
            position: absolute;
            bottom: -24px;
          }
        }
        #signupBtn,
        #dealifySignupBtn,
        #loginBtn,
        #recoverPasswordBtn,
        #resetPasswordBtn,
        .loginRedirectBtn,
        #resendBtn {
          height: 36px;
          margin-top: 30px;
          min-width: 200px;
          font-weight: bold;
          letter-spacing: 0.3px;
          font-size: 14px;
          @media (min-width: 768px) {
            font-size: 16px;
            min-width: 220px;
          }
          @media (min-width: 992px) {
            font-size: 18px;
            min-width: 260px;
            height: 44px;
          }
          &.btn-arrow::after {
            position: absolute;
            right: 20px;
            font-size: 12px;
            @media (min-width: 768px) {
              font-size: 14px;
            }
            @media (min-width: 992px) {
              font-size: 16px;
            }
          }
          &.animatedButton {
            background: #ff6057;
            &.loading {
              min-width: unset !important;
              width: 36px !important;
              height: 36px !important;
              @media (min-width: 992px) {
                width: 44px !important;
                height: 44px !important;
              }
              .load-text {
                width: 26px !important;
                height: 26px !important;
                @media (min-width: 992px) {
                  width: 34px !important;
                  height: 34px !important;
                }
              }
            }
          }
        }
        .loginRedirectBtn {
          text-decoration: none;
          min-width: 200px;
          margin: 40px auto 0;
        }
        .resetPasswordEmailSent,
        .resetPasswordSuccess {
          position: relative;
          display: block;
          width: 100%;
          .successTick {
            text-align: center;
            margin: 0 auto 15px;
            i {
              font-size: 72px;
              color: $bd-green;
            }
          }
          img {
            display: block;
            width: 80px;
            text-align: center;
            margin: 0 auto 30px;
            &.verifiedImg {
              width: 60px;
            }
          }
          .verified-title {
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0.45px;
            color: #00a70d;
          }
          h3 {
            text-align: center;
          }
          .descText {
            text-align: center;
            margin: 30px 0;
            color: $c-88;
          }
        }
        .dealifyRedirectPopup {
          margin-top: 15px;
        }
        .loginRedirect,
        .dealifyRedirectPopup {
          text-align: center;
          a,
          .loginLink {
            text-decoration: underline;
            color: #454545;
            cursor: pointer;
          }
        }
        .react-tel-input {
          width: 100%;
          position: relative;
          width: 100%;
          border: 2px solid $c-db;
          padding: 5px 10px;
          border-radius: 8px;
          @include flex-center;
          height: 32px;
          @media (min-width: 768px) {
            height: 36px;
            padding: 5px 10px;
          }
          @media (min-width: 768px) {
            height: 42px;
          }
          &:focus {
            border: solid 2px #cbd2ff;
          }
          .flag-dropdown {
            @include flex-center;
          }
          .flag-dropdown,
          .selected-flag {
            z-index: 1;
            border: none;
            background: white;
            .country-list {
              bottom: 42px !important;
              top: unset !important;
              left: 0 !important;
              @include wt-ht(300px !important, 200px !important);
              .country {
                height: 30px !important;
                font-size: 14px !important;
              }
              .country-name,
              .dial-code {
                font-family: $font-regular, sans-serif;
                color: $c-66;
                font-size: 14px !important;
              }
            }
          }
          input[type="tel"] {
            border: none;
            outline: none;
            box-shadow: none;
            color: $c-55;
          }
        }
      }
    }
  }
  .leftRegisterContent {
    overflow-y: auto;
    transition: overflow-y 0.3s ease-in-out;
    box-sizing: border-box;
    @media (min-width: 768px) {
      padding-top: 5px;
    }
    @media (min-width: 992px) {
      padding-top: 126px;
    }
    @media (min-width: 1200px) {
      padding-top: 21px;
    }
  }

  &.adjustHtEventRegistration {
    .leftRegisterContent,
    .teamContentSection,
    .registerBox {
      @media (min-width: 768px) {
        height: calc(100vh - 104px);
      }
    }
  }
}

.blockContent {
  position: relative;
  @include wt-ht(calc(100% - 15%), calc(100% - 180px));
  display: flex;
  justify-content: center;
  flex-direction: column;
  &.adjustBlockContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.registerContentBody {
  display: grid;
  grid-template-columns: calc(50% - 15px) 50%;
  gap: 15px;
  margin-top: 30px;
  align-items: center;
  &.fullWidth {
    grid-template-columns: 100%;
    gap: 0;
  }
  .pointListBgLine {
    color: white;
    ul {
      padding-left: 30px;
      margin: 0;
      li {
        font-size: 18px;
        line-height: 1.4;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
    .imgAlignRight {
      width: 60%;
      margin: 20px 0 0 auto;
      img {
        max-width: 100%;
      }
    }
  }
  .imgView {
    position: relative;
    display: block;
    min-height: 180px;
    max-height: 280px;
    width: 100%;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
  }
}

.teamSignup,
.teamLogin {
  @include wt-ht(300px, 100%);
  display: flex;
  padding: 60px 0;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  .thankYouMsgText {
    font-size: 24px;
    line-height: 1.5;
  }
  h3 {
    font-family: $font-semi-bold;
    margin-bottom: 30px;
    color: $c-3b;
    font-size: 26px;
    @media (min-width: 0) and (max-width: 767px) {
      font-size: 22px;
    }
  }
  h6 {
    font-size: 16px;
    color: $c-66;
    margin-bottom: 30px;
  }
  .teamEmailPopulate {
    position: relative;
    margin-bottom: 20px;
    display: block;
    label {
      position: relative;
      display: block;
      font-size: 12px;
      color: $c-66;
    }
    .email {
      padding: 2px 0;
      font-size: 16px;
      color: $blue-violet;
    }
    .errMsgText {
      color: red;
      font-size: 12px;
      display: block;
      left: 0;
      bottom: -15px;
    }
  }
  .btm-m-10 {
    bottom: -10px;
  }
  .forgot-desc-text {
    font-size: 14px;
    color: $c-66;
    margin-top: 15px;
  }
  .activateSuccess {
    text-align: center;
    .activateBoldText {
      margin: 15px 30px;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.4;
      letter-spacing: 0.3px;
      text-align: center;
      color: $c-22;
    }
    .activateSpinner {
      @extend %spinnerIcon;
    }
    .activateInfoText {
      font-size: 14px;
      color: $c-78;
      margin-bottom: 10px;
    }
  }
  .forgot-link-section,
  .activateSuccess {
    @extend %successGreenTick;
    position: relative;
    display: block;
    text-align: center;
    .forgot-link-text,
    .activateText {
      color: $c-44;
      text-align: center;
      margin: 25px 0;
    }
    .forgot-link-btn,
    .activateButton {
      text-align: center;
      margin-bottom: 20px;
      a {
        display: block;
        text-decoration: none;
        cursor: pointer;
        width: 120px;
        height: 42px;
        padding: 10px 0;
        margin: 0 auto;
        font-weight: 600;
        color: $white;
        background: $light-green;
        font-size: 16px;
        border-radius: 5px;
        transition: all 0.3s ease;
        &:hover {
          background: $green;
          transition: all 0.3s ease;
          box-shadow: 0 0 4px 2px $green-shadow-lg;
        }
      }
    }
  }

  .form-button {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    button {
      width: 75%;
      font-size: 14px;
      letter-spacing: 0.3px;
      padding: 12px 20px;
      font-weight: 500;
      justify-content: space-between;
      &.fill-transistion {
        z-index: 10;
        position: fixed;
        user-select: none;
        pointer-events: none;
      }
    }
  }
  .info-text-forgot {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    a {
      color: $c-78;
      text-decoration: none;
    }
  }
  p {
    margin-bottom: 20px;
  }
  .staticLabel {
    top: -4px;
    font-size: 12px;
  }
  .telephonicInputCont {
    margin-top: 5px;
    .react-tel-input {
      width: 100%;
      position: relative;
      border-bottom: 1px solid $c-dd;
      .flag-dropdown,
      .selected-flag {
        z-index: 1;
        border: none;
        background: white;
        .country-list {
          @include wt-ht(300px !important, 200px !important);
          .country {
            height: 30px !important;
            font-size: 14px !important;
          }
          .country-name,
          .dial-code {
            font-family: $font-regular, sans-serif;
            color: $c-66;
            font-size: 14px !important;
          }
        }
      }
      input[type="tel"] {
        height: 30px;
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }
}
.info-text {
  text-align: center;
  color: $c-78;
  font-size: 14px;
  line-height: 1.4;
  margin-top: 20px;
  a,
  .logIn {
    color: #465fff;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  }
}
.formBottomText {
  text-align: center;
  color: $c-78;
  font-size: 14px;
  height: 40px;
  @include flex-center;
  a {
    color: #465fff;
    text-decoration: none;
    font-weight: bold;
  }
}
.resendBtnContainer {
  color: $c-66;
  text-align: center;
  .message {
    font-size: 13px;
    color: $c-66;
    padding-bottom: 20px;
  }
}
