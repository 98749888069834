.feature-comparison-section {
  padding: 2rem 0;
  background-color: $white;
  .table-container,
  .container,
  th {
    overflow: visible;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .table-container {
    overflow-x: auto;
    .mostPopularBadgeText {
      color: transparent;
      user-select: none;
    }

    .comparison-table {
      width: 100%;
      border-collapse: collapse;
      text-align: center;
      background-color: $c-ffffff;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      .customTooltip {
        position: relative;
        z-index: 100;
        right: -31px;
        top: 6px;

        .sharpPointer {
          border-top-right-radius: 2px;
          padding: 5px;
          border-right: 1px solid black;
          border-top: 1px solid black;
          right: 101px;
          background: black;
        }

        .tooltipBody {
          background: black;
          right: 48px;
          top: 3px;
          width: 200px;
          border-radius: 4px;
          z-index: 200;

          .tooltipText {
            color: white;
            white-space: initial;
            padding: 6px;
            font-size: 11px;
            text-align: center;
          }
        }
      }
      .plan-header {
        text-align: center;
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        .pricing-subheader {
          font-size: 24px;
          color: $c-0b004e;
          font-weight: 600;
        }
        .pricing-subcontent {
          font-size: 16px;
          color: $c-000000;
          font-weight: 500;
          padding: 10px 0;
        }
        .pricingToogle {
          padding: 10px 0;
        }
      }
      h6 {
        font-size: 24px;
      }
      .mostPopularBadge {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        padding: 4px 8px;
      }

      .priceDataHeader {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        padding: 7px;
        .priceData {
          .subscript {
            margin-top: 13px;
          }
          color: $c-333333;
          font-size: 13px;
          font-weight: 500;
          min-height: 60px;
          display: flex;
          align-items: center;
          span {
            font-size: 34px;
            font-weight: 600;
            .superscript {
              font-size: 16px;
            }
          }
        }
      }
      th {
        text-align: left;
      }
      th,
      td {
        padding: 1rem;
        border: 1px solid #e0e0e0;
        color: #000;
        font-weight: 500;
        font-size: 16px;
      }

      thead th {
        background-color: $white;
      }

      .table-title th {
        font-weight: bold;
        text-align: left;
      }
      .table-title {
        background-color: $c-f4f5f7;
      }

      .headerContent {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .toggleIcon {
        width: 22px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .check {
        color: #4caf50;
        font-size: 1.5rem;
      }

      .close {
        color: #f44336;
        font-size: 1.5rem;
      }

      .btn {
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
      }

      .btn-redirect {
        background-color: transparent;
        border: 1.5px solid $blue-violet;
        color: $blue-violet;
      }

      .btn-chat {
        background-color: $blue-violet;
        color: $white;
      }
    }
  }
}
