// Font
$font-regular: "Graphik-Regular";
$font-medium: "Graphik-Medium";
$font-bold: "Graphik-Bold";
$font-semi-bold: "Graphik-Semibold";

// Chip
$chip-grey: #eaeaea !default;

// Background
$bg-default: #f3f4f8 !default;
$c-f9: #f9f9f9 !default;
$c-f5: #f5f5f5 !default;
$c-f1: #f1f1f1 !default;
$c-fa: #fafafa !default;
$c-ec: #ececec !default;
$bg-blue: #f9faff !default;
$c-a7: #a7a7a7 !default;
$c-be: #bebebe !default;
$c-ee: #eeeeee !default;
$c-af: #afafaf !default;
$c-f9f9: #f9f9ff !default;

// Button
$c-9e: #9e9e9e !default;

// Color
$black: #000 !default;
$white: #fff !default;
$red: red !default;
$blue: #2080ee !default;
$orange: orange !default;
$green: #29b969 !default;
$light-green: #2dca73 !default;
$dark-blue-violet: #1732a4 !default;
$blue-violet: #3953fb !default;
$lb-blue: #2176b7 !default;
$b-c-1: #eceff1 !default;
$bright-blue: #2542ff !default;
$c-ff6057: #ff6057 !default;
$c-00A358: #00a358 !default;
$c-FF0000: #ff0000 !default;
$c-F59064: #f59064 !default;
$c-0a66c2: #0a66c2 !default;
$c-00a358: #00a358 !default;
$c-f59064: #f59064 !default;
$c-f5906429: #f5906429 !default;
$c-00a35829: #00a35829 !default;
$c-1d2026: #1d2026;
$success-green: #00d274;

//fade-color
$fade-yellow: #ffe6b5 !default;
$fade-violet: #c7c5f8 !default;
$fade-green: #4cc8a1 !default;
$fade-blue: #c9fdf6 !default;
$fade-red-orange: #f7cbcd !default;
$fade-blue-green: #c1f9d3 !default;
$fade-sandal: #f9dec1 !default;
$fade-light-green: #d3ffc4 !default;
$fade-pink: #f5d1ff !default;
$fade-orange: #efb79c !default;
$fade-violet: #3953fb29 !default;
$blue-transparent: #eceeff !default;

$c-66: #666 !default;
$c-65: #656565 !default;
$c-78: #787878 !default;
$c-87: #878787 !default;
$c-ab: #ababab !default;
$c-7b: #7b7b7b !default;
$c-88: #888 !default;
$c-88: #888 !default;
$c-71: #717171 !default;
$c-1a: #1a1a1a !default;
$c-1b: #1b1b1b !default;
$c-22: #222 !default;
$c-33: #333 !default;
$c-3b: #3b3b3b !default;
$c-44: #444 !default;
$c-55: #555 !default;
$c-52: #525252 !default;
$c-57: #575757 !default;
$c-85: #858585 !default;
$c-59: #595959 !default;
$c-42: #424242 !default;
$c-94: #949494 !default;
$c-17: #171717 !default;
$c-26: #262626 !default;
$c-a2: #a2a2a2 !default;
$c-1a: #1a1a1a !default;
$c-81: #818181 !default;
$c-27: #272727 !default;
$c-50: #505050 !default;
$c-f0: #f0f0f0 !default;
$c-8d: #8d8d8d !default;
$c-343a40: #343a40 !default;
$c-e5: #e5e5e5 !default;
$c-333333: #333333;

// border
$c-cc: #ccc !default;
$c-e1: #e1e1e1 !default;
$c-ea: #eaeaea !default;
$c-dd: #ddd !default;
$c-de: #dedede !default;
$c-db: #dbdbdb !default;
$c-ee: #eee !default;
$c-f4: #f4f4f4 !default;
$c-f7: #f7f7f7 !default;
$c-f5: #f5f5f5 !default;
$c-eb: #ebebeb !default;
$c-c7: #c7ceff !default;
$c-c4: #c4c4c4 !default;
$c-45: #454545 !default;

// Color
$c-53: #535353 !default;
$c-d3: #d3d3d3 !default;
$c-e8: #e8e8e8 !default;
$color-green: #6ac259 !default;
$color-orange: #ff915b !default;
$color-yellow: #ffbf43 !default;
$orange-btn: #ff9001 !default;
$dark-orange: #ea8605 !default;
$c-ad: #adadad !default;
$blue-btn-hover: #2743f4 !default;
$c-73: #737373 !default;
$c-cb: #cbcbcb !default;
// Light Bg Color
$lg-blue: #fafbff !default;
$grad-yellow: #ff9a18 !default;
$purple: #525bca !default;
$pink: #e943ac !default;
$lightGreen: #50cfc5 !default;
$c-blue: #a2aeff !default;
$c-8a: #8a8e8c !default;
$c-97: #979a9d !default;
$c-88: #888 !default;
$c-5c: #5c5c5c !default;
$c-e0: #e0e0e0 !default;
$c-73: #737373 !default;
$c-f1: #f1f1f1 !default;
$c-99: #999 !default;
$c-d8: #d8d8d8 !default;
$c-f0: #f0f0f0 !default;
$c-fb: #fbfbfb !default;
$ct-green: #5eab13 !default;
$c-c7: #c7c7c7 !default;
$c-aa: #aaaaaa !default;
$c-e3: #e3e3e3 !default;
$c-75: #757575 !default;
$c-38: #38b892 !default;
$c-4b: #4b4f86 !default;
$c-fa: #fafafb !default;
$c-ff9: #ff9f09 !default;
$c-4c: #4c4c4c !default;
$c-ed: #ededed !default;
$c-f5f8: #f5f8fd !default;
$c-a5: #a5a5a5 !default;
$c-a0: #a0a0a0 !default;
$c-5b: #5b5b5b !default;
$c-f2: #f2f2f2 !default;
$c-e7: #e7e7e7 !default;
$c-fd: #fdfdfd !default;
$c-fc: #fcfcfc !default;
$c-74: #747474 !default;
$c-50: #505050 !default;
$c-4e: #4e4e4e !default;
$c-f3: #f3f3f3 !default;
$c-7e: #7e7e7e !default;
$c-fb656: #fb6565 !default;
$c-4543: #454343 !default;
$c-10c: #10cba7 !default;
$c-0635: #0635c9 !default;
$c-48: #484848 !default;
$c-88: #888 !default;
$c-ffb4b4: #ffb4b4 !default;
$c-0177ff: #0177ff !default;
$c-ff4b2e: #ff4b2e !default;
$c-e8e3e6: #e8e3e6 !default;
$c-fffff9: #fffff9 !default;
$c-f8f8b7: #f8f8b7 !default;
$c-5a: #5a5a5a !default;
$c-4a: #4a4a4a !default;
$c-3c: #3c3c3f !default;
$c-1eb9d5: #1eb9d5 !default;
$onBoardingGreen: #2fd378 !default;
$c-8c8c8c: #8c8c8c !default;
$c-d6d6: #d6d6d6 !default;
$c-9d9d: #9d9d9d !default;
$c-bebe: #bebebe !default;
$c-ae: #aeaeae !default;
$c-28: #28ad56;
$c-d0d0d0: #d0d0d0;
$c-f7f7f7: #f7f7f7;
$c-e6e6e6: #e6e6e6;
$c-3953fb: #3953fb;
$c-ffffff: #ffffff;
$c-000000: #000000;
$c-0b004e: #0b004e;
$c-f4f5f7: #f4f5f7;
$c-d9d9d9: #d9d9d9;

/* Progress */
$progress-bar-bg: #e9eef1 !default;
$progress-bar-color: #2fdc47 !default;
$step-circle: #d5d7e9 !default;
$c-green: #39d81a !default;

// Shadow
$green-shadow-lg: rgb(206, 254, 206);

// Badge
$bd-green: #3ecb6f !default;

// Table
$tb-bor-btm: $b-c-1 !default;
$c-f8: #f8f8f8 !default;

// Banner Color Code
$hb-sandal-yellow: #fff0cf !default;
$hb-text: #ff7835 !default;

//dealify
$c-847c7c: #847c7c;
$c-666666: #666666;
$c-106aff: #106aff;

//Pricing plans
$c-freePlan: #505050;
$c-basicPlan: #3b50fa;
$c-proPlan: #ff6057;

// common
$c-373772: #373772;

//Dashboard
$c-77787c: #77787c;
$mild-grey: #edf0f4;
$mild-blue: #f2f5fa;
$border-grey: #e8ebee;
$c-77787c: #77787c;
$c-8f: #8f8f8f;
$c-eb: #ebebeb;
$c-d8: #d8d8d8;
$c-66: #666666;
$c-7c: #7c7c7c;
$c-b1: #b1b1b1;
$c-2080ed: #2080ed;
$c-0177ff: #0177ff;
$c-bdc0c2: #bdc0c2;
$c-666870: #666870;
$c-8e9193: #8e9193;
$c-1431f1: #1431f1;
$c-bbc4ff: #bbc4ff;
$c-4c5054: #4c5054;
$c-8a8787: #8a8787 !default;
$c-1e: #1e1e1e;

//Register Page
$c-36d593: #36d593;

// Contact stages

$contact-stage-border: #959cf2;

// Grid Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  2xl: 1400px,
  3xl: 1600px,
  4xl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  2xl: 1280px,
  3xl: 1440px,
  4xl: 1600px
) !default;

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Utilities
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex,
  inline-flex !default;
$overflows: auto, hidden !default;
$positions: static, relative, absolute, fixed, sticky !default;
