// CircularLoader.scss
.circular-loader-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

.circular-loader {
  width: 15px;
  height: 15px;
  border: 2px solid rgba(255, 255, 255, 0.3); // White with 30% opacity
  border-radius: 50%;
  border-top-color: #000000; // Black spinner
  animation: spin 1s ease-in-out infinite;
  display: inline-block;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}
