@mixin icon {
  width: 14px;
  height: 14px;

  &.animatedStar {
    margin-right: 3px;
  }

  svg {
    width: inherit;
    height: inherit;
    fill: #787878;
  }
}

@mixin dilaerUpgrade {
  width: 100%;
  height: 100%;

  .dialerUpgradeCont {
    width: 100%;
    height: 100%;
    @include flex-direction(row);
    justify-content: center;
    .upgradeTooltipSection {
      left: 75px;
      .tooltipCard .subTitle,
      .tooltipCard .descText {
        color: $black;
      }
      .pointer {
        display: none;
      }
    }

    .dialerImageCont {
      img {
        width: 245px;
        object-fit: contain;
        height: auto;
      }
    }

    .upgradeCont {
      width: 30%;
      padding-left: 20px;
      position: relative;

      .upgradeText {
        color: $c-33;
        font-size: 14px;
        line-height: 1.3;
        letter-spacing: normal;
      }

      .upgradeButton {
        padding-top: 10px;

        .btn {
          background-color: $orange-btn;
          color: $white;
          @include wt-ht(100px, 30px);
          font-size: 12px;
          cursor: pointer;
        }
      }

      .arrowIcon {
        padding-top: 15px;
        position: relative;

        img {
          position: absolute;
          width: 65px;
          height: auto;
          left: -25px;
        }
      }
    }
  }
}

@mixin availableCreditsCard {
  padding-top: 40px;

  .availableCredits {
    margin: 0px 10px 10px 0px;
    padding: 9px 5px;
    width: 350px !important;
    height: 140px;

    .freecalling {
      @include flex-center;
      margin-top: 12px;
      .freecallingText {
        margin-right: 10px;
      }
      .freecallingIcon {
        display: flex;
        i {
          font-size: 16px;
        }
      }
      .customTooltip {
        .tooltipText {
          color: $white;
        }
        .tooltipBody {
          background: #393a3d;
          top: 18px;
          right: -216px;
          color: $white;
          width: 370px;
        }
        .sharpPointer {
          background-color: #393a3d;
          top: 9px;
          right: 0;
        }
      }
    }
    .creditsCont {
      width: 100%;
      padding: 0px 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 14px;
      color: $c-66;

      .creditsData {
        font-family: "Graphik-Semibold";
        padding-top: 5px;
        color: $blue-violet;
        font-size: 16px;
      }
    }

    .actionBtns {
      color: $white;
      font-size: 12px;
      cursor: pointer;
      padding: 0px 10px;
      background-color: $blue-violet;
      @include flex-center;
      justify-content: center;
      width: 150px;
      height: 32px;
      margin-left: 10px;
      margin-top: 16px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

@mixin dialer {
  width: 100%;
  height: 100%;
  display: inline-block;
  box-sizing: border-box;
  padding: 20px;
  overflow-y: auto;

  .adminPopupMask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 199;
    background: #333;
    opacity: 0.75;
  }

  .dialerUpgrade {
    @include dilaerUpgrade();
  }

  .contactAdminPopup {
    @include adminPopup();
  }

  .dialerTitle {
    color: $c-66;
    font-family: $font-regular;
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
  }

  .cardWrapper {
    display: grid;
    grid-area: auto;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
  }

  .card {
    width: 210px;
    height: 80px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 3.4px 5px 4px 0 rgba(124, 130, 165, 0.1);
    border: solid 1px $c-d3;
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding: 7px 5px 9px 5px;
    position: relative;

    &.newNumberCard {
      color: rgba(102, 102, 102, 0.65);
      font-size: 11px;
      cursor: pointer;

      .phoneNumberCardheader {
        justify-content: center;
        align-items: center;
        height: 100%;

        .addNewNumber {
          @include phoneNumber();
          justify-content: center;
        }
      }
    }

    .otherDetails {
      @include flex-align-path-justify(flex-start, column, flex-end);
      height: 50px;

      .phoneNumber {
        @include phoneNumber();
      }

      .delete.icon {
        cursor: pointer;
        @include icon();
      }
    }

    .phoneNumberCardheader {
      width: 100%;
      padding: 0px 10px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      height: 55px;

      .messageCont {
        @include flex-center();
        background: $fade-yellow;
        border-radius: 20px;
        padding: 3px 5px;
        &.freeTextCont {
          background: #2dca73;
        }

        .messageIcon {
          margin-right: 5px;
          color: $black;
          font-size: 10px;
        }

        .messageText {
          font-size: 10px;
          color: $black;
          line-height: 10px;
        }
      }

      .star.icon {
        cursor: pointer;
        @include icon();
      }

      .defaultNumber {
        @include flex-center();
        cursor: default;

        .star.filled {
          margin: 0px;
          position: relative;
          height: 14px;
          width: 14px;

          img {
            height: inherit;
            width: inherit;
            position: absolute;
          }
        }
      }

      .phoneNumber {
        @include phoneNumber();
      }
    }
  }

  .creditsWrapper {
    @include availableCreditsCard();
  }
}

@mixin phoneNumber {
  color: $blue-violet;
  font-size: 14px;
  @include flex-center();
  justify-content: flex-start;
  width: 100%;

  &.cardRow {
    padding: 5px 10px;
  }
}

@mixin customUrl {
  width: 100%;
  height: 100%;
  display: inline-block;
  box-sizing: border-box;
  padding: 20px;
  overflow-y: auto;

  .customUrlheader {
    .urlTitle {
      padding-bottom: 20px;
      box-sizing: border-box;
      font-size: 15px;
      font-family: $font-regular;
      color: $c-66;
    }
  }
}

@mixin toolTip {
  .toolTipCont .UpgradeTooltip {
    top: -162px;
    .sharpPointer {
      top: 159px;
    }
  }
}

.settingsPage {
  .settingSection {
    position: relative;
    @include wt-ht(100%, calc(100% - 60px));
    display: grid;
    grid-template-columns: 260px calc(100% - 260px);
    padding: 12px;
    &.productDiscount {
      @include wt-ht(100%, calc(100% - 114px));
    }
    .settingsRightSectionWrapper {
      position: relative;
      display: flex;
      @include wt-ht(100%, 100%);
    }

    .leftSection {
      @extend %relBlock;
      @include wt-ht(260px, 100%);
      padding-right: 10px;
      .tabContainer {
        @extend %relBlock;
        height: 100%;
        overflow-y: auto;
        background: $white;
        border-radius: 10px;
        .tabValue {
          cursor: pointer;
          height: 55px;
          @include flex-center;
          padding-left: 20px;
          border-bottom: 1px solid $c-ec;
          font-size: 16px;
          font-family: $font-regular;
          position: relative;
          text-decoration: none;
          color: $c-78;
          &:hover {
            background-color: $c-fa;
            .rightArrow {
              transform: translate(20%, 0);
            }
          }
          .navWrapper {
            @include flex-center();
          }

          svg {
            display: none;
            @include wt-ht(18px, 18px);
          }

          .tab {
            width: fit-content;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            overflow: hidden;
            font-size: 14px;
            margin-right: 10px;
          }

          &.selected {
            color: $blue-violet;

            .rightArrow {
              position: absolute;
              right: 20px;
              @include wt-ht(18px, 11px);
              max-width: 30%;
              transition: transform 0.3s ease;
              @include flex-center();

              svg {
                display: block;
                @include wt-ht(18px, 15px);

                path {
                  fill: $blue-violet;
                }
              }
            }
          }

          &.subMenu {
            .tab {
              width: 100%;
              text-align: center;
              height: inherit;
              display: flex;
              align-items: center;
            }

            .subMenuList {
              width: 100%;
              height: 0px;
              border: 0;

              .tabValue {
                height: 0px;
                border: 0;

                .tab {
                  font-size: 0;
                }
              }
            }

            &.openSubMenu {
              flex-direction: column;
              padding: 0px;
              height: auto;
              border: 0px;

              &:hover {
                background-color: transparent;
              }

              & > .tab {
                height: 55px;
                box-sizing: border-box;
                @include flex-center();
                padding-left: 20px;
                font-weight: 600;

                &:hover {
                  background-color: $c-fa;
                }
              }

              .subMenuList {
                height: 100%;

                .tabValue {
                  height: 55px;
                  padding-left: 40px;
                  border-bottom: 1px solid $c-ec;

                  .tab {
                    font-size: 14px;
                  }
                }
              }
            }

            .tabText {
              width: 80%;
              @include ellipsis();
              text-align: left;
            }

            .subMenuDownArrow {
              position: absolute;
              right: 20px;
              transition: transform 0.3s ease;
              color: $blue-violet;
            }
          }
        }
      }
    }

    .rightSection {
      width: 100%;

      .loading {
        @include flex-center();
        justify-content: center;
        height: inherit;
      }

      .autoBccWrapper,
      .crmIntegration,
      .dialerWrapper,
      .schedulesWrapper,
      .safetySettingsWrapper {
        padding: 10px;
        height: 100%;
        -webkit-user-select: none;
        user-select: none;
        overflow-y: auto;
      }

      .crmIntegration {
        overflow: unset;
      }

      .autoBccWrapper .buttonCont {
        padding-top: 20px;
        display: flex;
        align-items: center;

        button {
          margin: 0;
        }
      }

      .customFields,
      .autoBccWrapper,
      .safetySettingsWrapper {
        .addSignBtn,
        .addBccBtn {
          @extend %primaryBlueBtn;
        }
        .emailSigCont,
        .autoBccInnerCont,
        .cusotmFieldsInnerCont {
          @include wt-ht(100%, 400px);
          max-width: 700px;
          display: inline-block;
          overflow: auto;
          box-sizing: border-box;
          padding: 20px;

          #reactSignatureEditor {
            @extend %editorConfig;
          }
        }
        .cusotmFieldsInnerCont {
          max-width: 100%;
        }
        .autoSignature,
        .autoBcc {
          @include flex-center();
          padding: 0px 20px;

          .autosignText {
            padding-left: 10px;
            font-size: 15px;
            color: $c-66;
            line-height: 15px;
          }

          .check-box {
            border: 1px solid #b2b2b2;
            background: $white;
            border-radius: 4px;
            width: 15px;
            height: 15px;

            .fa-check {
              display: flex;
              font-size: 13px;
              color: $white;
              justify-content: center;
              height: 100%;
              display: none;
            }
          }

          .selected {
            &.check-box {
              background-color: #2dca73;
              border-color: #2dca73;

              .fa-check {
                display: flex;
                font-size: 13px;
                color: $white;
                justify-content: center;
                height: 100%;
              }
            }
          }
        }

        .signatureTitle,
        .headerTitle {
          @extend %titleTextHeading;
        }
      }

      .schedulesWrapper .schedulesCont {
        width: 100%;
        overflow: reset;
        box-sizing: border-box;
        padding: 20px;

        .singleSelectDropdown {
          label.selectOption {
            height: 62px;
            align-items: flex-end;
          }
          .selectLabel {
            display: block;
            height: 62px;
          }
          .labelName {
            font-size: 16px;
            margin-bottom: 10px;
            bottom: unset;
            position: relative !important;
          }
          .activeValue {
            width: 100%;
            @include ellipsis();
          }
          .arrowUpDown {
            position: absolute;
            right: 0;
            bottom: 13px;
          }
        }

        .headingWrapper {
          @include flex-center();
          justify-content: space-between;
          margin-bottom: 8px;
          .headingText {
            font-size: 16px;
            color: $c-66;
          }
        }
        .backBtn {
          position: relative;
          border-radius: 6px;
          @include flex-center;
          margin-bottom: 15px;
          color: $c-78;
          font-size: 14px;
          width: fit-content;
          padding: 5px 10px;
          transition: all 0.3s ease;
          cursor: pointer;
          border: 1px solid $c-ee;
          i {
            font-size: 20px;
            margin-right: 5px;
          }
          &:hover {
            background: $c-f5;
            transition: all 0.3s ease;
            color: $c-33;
          }
        }
        .buttonCont {
          background-color: $blue-violet;
          border-color: $blue-violet;
          height: 36px;
          width: 130px;
          color: #fff;
          font-size: 14px;
          text-transform: capitalize;
          @include flex-center();
          justify-content: center;
          border-radius: 5px;
          cursor: pointer;
          border: 0;
          font-family: $font-regular;
          &.disabled {
            opacity: 0.5;
            pointer-events: none;
            cursor: not-allowed;
          }
        }
        .schedulesTable {
          overflow: auto;
        }
        .individualScheduleWrapper {
          display: grid;
          grid-template-columns: 20% calc(50% - 64px) 30%;
          @include wt-ht(100%, 55px);
          justify-content: space-between;
          border-bottom: 1px solid $c-ec;
          font-size: 14px;
          font-family: $font-regular;
          cursor: pointer;
          padding: 0 8px;
          &:hover {
            background-color: $c-f8;
          }
          .scheduleNameWrapper,
          .scheduleDates,
          .scheduleTimeZone {
            @include flex-center();
            color: $c-66;
          }
          .scheduleTimeZone .linear-background {
            width: 180px;
          }

          .scheduleNameWrapper {
            position: relative;
            @include flex-center();
            .scheduleName {
              color: $c-66;
              font-family: $font-medium;
            }
            .defaultValue {
              position: relative;
              letter-spacing: 0.4px;
              height: 24px;
              font-size: 10px;
              border-radius: 3px;
              margin-left: 10px;
              text-transform: uppercase;
              @include flex-center;
              background: $c-e3;
              color: $c-75;
              padding: 2px 6px 0;
            }
            .linear-background {
              width: 160px;
            }
          }
          .scheduleDates .linear-background {
            width: 160px;
          }
        }
        .createScheduleWrapper {
          padding-top: 12px;
          font-size: 14px;
          font-family: $font-regular;
          .fieldContainer {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
            width: 100%;
            flex: 0 0 100%;
            .dropdownPanel {
              width: 100%;
              .dropdownList {
                width: 100%;
              }
            }
            .fieldWrapper {
              flex: 0 0 50%;
              width: 350px;
              &:first-child {
                margin-right: 12px;
              }
              input,
              .autoCompleteDropdown,
              .autoCompleteDropdown .dropdownHeader {
                width: 100%;
              }
              .dropdownList .updatedName {
                max-width: 320px;
              }
            }
          }
          .saveSchedule {
            @extend %primaryBlueBtn;
            margin: 0;
            border-radius: 6px;
            margin-right: 8px;
            border: 1px solid $blue-violet;
          }
          .fieldWrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            position: relative;
            .labelText {
              margin-bottom: 10px;
              font-size: 16px;
            }
            .required {
              color: red;
            }
            .err-msg-text {
              bottom: -21px;
              left: 0;
              right: unset;
            }
          }
          .scheduleName {
            border: 0;
            border-bottom: 1px solid $c-eb;
            width: 300px;
            color: $blue-violet;
            font-size: 14px;
            padding: 5px 0 10px;
          }
        }
        .sendingWindowWrapper {
          @extend %sendingWindowWrapper;
        }
      }

      .autoBccWrapper {
        .bccBodyCont {
          &.disabled {
            cursor: unset !important;
            opacity: 1;
            input,
            .commonFilledCheckBox .emptyCheckBox {
              background-color: $c-f0;
              pointer-events: none;
              opacity: 0.75;
            }
            .buttonCont,
            .checkBoxSection {
              pointer-events: none;
              opacity: 0.75;
            }
            .thunderSvg {
              position: absolute;
              right: 0;
              top: 5px;
            }
            .upgradeTooltipSection {
              right: -110px;
              .pointer {
                right: 116px;
                top: -5px;
              }
            }
          }
        }
        .inputCont {
          width: 80%;
          padding-bottom: 20px;
          position: relative;

          input {
            height: 35px;
            border-radius: 4px;
            background-color: $white;
            border: solid 1px #cdccd5;
            color: #4c4761;
            font-size: 14px;
            padding: 0 10px;
            width: 100%;

            &:focus {
              border-color: $blue-violet;
            }
          }
        }

        .autoBcc {
          padding: 0px;
        }
      }

      .crmInnerCont {
        display: block;
        box-sizing: border-box;
        padding: 20px;
        overflow-y: auto;
        .crmType {
          padding-top: 20px;
          height: fit-content;

          .crmTypeCont {
            display: grid;
            grid-template-columns: repeat(auto-fill, 320px);
            height: fit-content;
            grid-gap: 30px;

            .crmCard {
              border: solid 1px #d3d5e3;
              background-color: white;
              border-radius: 10px;
              padding: 10px 20px 20px;
              box-shadow: 3.4px 5px 4px 0 rgba(124, 130, 165, 0.1);
              position: relative;
              display: grid;
              .zapierApiIntegrationWrapper {
                .buttonCont {
                  padding-top: 12px;
                }
              }

              .titleHeaderFlex {
                @include flex-center();
                .upgradeBadge {
                  margin-left: 10px;
                }
              }

              .toolTipCont {
                position: relative;
                .upgradeTooltipSection {
                  &.topLeft {
                    top: 23px;
                    left: -260px;
                    .pointer {
                      top: 2px;
                      left: 231px;
                    }
                  }
                  &.topRight {
                    top: 26px;
                    right: -40px;
                    .pointer {
                      top: 2px;
                      right: 53px;
                    }
                  }
                }
              }
              .upgradeButton {
                cursor: pointer;
                @include flex-center();
                justify-content: center;
                flex: 0 0 145px;
                height: 42px;
                background-color: #fff;
                color: #3953fb;
                border: 1px solid #3953fb;
                font-weight: bold;
                font-size: 1rem;
                border-radius: 5px;
                margin: 0 auto;
              }
              .crmCardHeader {
                @include flex-direction(row);
                justify-content: space-between;
                .titleHeader {
                  .title {
                    font-family: $font-medium;
                    color: $c-66;
                    text-align: left;
                  }
                  .connectSfText {
                    @include flex-center;
                    font-size: 14px;
                    color: $c-78;
                    margin-top: 4px;
                    i {
                      font-size: 14px;
                      color: greenyellow;
                      margin-right: 5px;
                    }
                  }
                }
                .flexRow {
                  @include flex-center();
                  .linkToSupportArticle {
                    text-decoration: none;
                    color: $c-66;
                  }
                  .helpIcon {
                    font-size: 18px;
                    margin-left: 8px;
                  }
                }

                .icon {
                  width: fit-content;
                  height: 50px;
                  object-fit: contain;
                  @include flex-center();
                }

                &.slack {
                  .icon {
                    img {
                      width: 90px;
                      height: auto;
                      object-fit: contain;
                    }
                  }
                  ~ .buttonCont .UpgradeTooltip {
                    top: -128px;
                    .sharpPointer {
                      top: 125px;
                    }
                  }
                }
                &.zapier {
                  cursor: default;
                  .icon {
                    img {
                      width: 85px;
                      height: auto;
                      object-fit: contain;
                      cursor: default;
                    }
                  }
                }
                &.zoho {
                  .icon img {
                    width: 45px;
                    height: auto;
                  }
                }
              }

              .apiKeyWrapper {
                margin-top: 16px;
                .apiKeyContainer {
                  margin-bottom: 0;
                }
              }
              .desc {
                padding: 25px 0px;
                font-family: $font-regular;
                font-size: 14px;
                letter-spacing: -0.16px;
                text-align: left;
                line-height: 1.46;
                cursor: default;
              }

              .comingSoonCont {
                @include flex-center();
                justify-content: center;
                padding-top: 25px;

                .comingsoonText {
                  font-size: 15px;
                  color: $blue-violet;
                  padding: 2px;
                }

                .contactUs {
                  font-size: 15px;
                  a {
                    color: $blue-violet;
                    text-decoration: underline;
                  }
                }
              }

              .buttonCont {
                @include flex-center();
                justify-content: center;
                padding-top: 29px;
                position: relative;

                .connectedBtnCont {
                  cursor: pointer;
                  flex: 0 0 145px;
                  height: 42px;
                  background: $white;
                  color: $blue-violet;
                  border: 1px solid $blue-violet;
                  font-weight: bold;
                  font-size: 1rem;
                  text-decoration: none;
                  &.disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                    pointer-events: none;
                    user-select: none;
                  }
                  &.btn {
                    background: $white;
                    color: $blue-violet;
                    border: 1px solid $blue-violet;
                  }
                  i {
                    padding-right: 5px;
                  }
                }
                .lockWrapper {
                  position: relative;
                  margin-right: 1.5rem;
                  display: flex;
                }
                .lockBtnCont,
                .playBtnCont,
                .setingSfIcon {
                  background-color: $white;
                  border: 1px solid $blue-violet;
                  @include flex-center();
                  flex: 0 0 42px;
                  height: 42px;
                  justify-content: center;
                  margin-left: 10px;
                  border-radius: 5px;
                  cursor: pointer;
                  svg {
                    fill: $bright-blue;
                  }
                }

                .playBtnCont,
                .setingSfIcon,
                .lockBtn {
                  border: 1px solid #a5a5a5;
                  svg {
                    fill: #a5a5a5;
                  }
                }
                &.wrapConnectedBtn {
                  .setingSfIcon {
                    position: relative;
                    @include flex-center;
                    justify-content: center;
                    border-radius: 8px;
                    border: 1px solid #a5a5a5;
                    background: $white;
                    flex: 0 0 42px;
                    height: 42px;
                    transition: all 0.3s ease;
                    cursor: pointer;
                    svg {
                      @include wt-ht(18px, 18px);
                      fill: $c-66;
                    }
                    &:hover {
                      background: #d9dbe7;
                      svg {
                        fill: $blue-violet;
                      }
                      transition: all 0.3s ease;
                    }
                  }
                }

                .connectBtnCont,
                .installbtnCont {
                  &.btn {
                    cursor: pointer;
                    @include flex-center();
                    flex: 0 0 145px;
                    height: 42px;
                    background-color: $white;
                    color: $blue-violet;
                    border: 1px solid $blue-violet;
                    font-weight: bold;
                    font-size: 1rem;
                    border-radius: 5px;
                  }
                  &.disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                  }
                  i {
                    margin-right: 5px;
                    font-size: 22px;
                  }
                }
                .installbtnCont {
                  svg {
                    fill: $blue-violet;
                    margin-right: 12px;
                    @include wt-ht(18px, 18px);
                  }
                }
              }

              .comingSoonCont {
                flex-direction: column;
              }
            }
          }
        }
        .mainHeading {
          margin-bottom: 24px;
          font-size: 16px !important;
        }

        .headingText {
          @include flex-center;
          transition: all 0.3s ease;
          font-size: 14px;
          i {
            margin-right: 10px;
            padding: 5px;
            cursor: pointer;
            border-radius: 35px;
            color: $c-78;
            transition: all 0.3s ease;
            &:hover {
              color: $c-33;
              background: $c-f5;
              transition: all 0.3s ease;
            }
          }
          .title {
            transition: all 0.3s ease;
          }
        }
      }

      .dialerInnercont {
        @include dialer();
      }

      .customUrlInnerCont {
        @include customUrl();
      }
    }
  }
}

.input-box ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 99;
}

.input-box ~ .focus-border:before,
.input-box ~ .focus-border:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: $blue-violet;
  transition: 0.4s;
}

.input-box ~ .focus-border:after {
  left: auto;
  right: 0;
}

.input-box:focus ~ .focus-border:before,
.input-box:focus ~ .focus-border:after {
  width: 50%;
  transition: 0.4s;
}

/* -------------- Unsubscribe --------------- */
.unsubscribeWrapper {
  @extend %relBlock;
  padding: 10px;
  height: 100%;
  -webkit-user-select: none;
  user-select: none;
  overflow-y: auto;
  &.disabled {
    opacity: unset;
    .quill,
    .ql-toolbar {
      background-color: $c-f0;
    }
    .quill,
    .ql-toolbar {
      pointer-events: none;
      opacity: 0.75;
    }
  }
  .unsubscribeCont {
    @include wt-ht(100%, 360px);
    max-width: 700px;
    display: inline-block;
    overflow: auto;
    box-sizing: border-box;
    padding: 20px;
    position: relative;

    .thunderSvg {
      position: absolute;
      right: 18px;
      top: 20px;
      cursor: pointer !important;
      g {
        filter: unset;
      }
    }
    .upgradeTooltipSection {
      top: 42px;
      right: 0;
      padding-top: 10px;
      .pointer {
        top: 2px;
        right: 22px;
      }
    }

    #reactunsubscribeEditor {
      .ql-image {
        display: none !important;
      }

      @extend %editorConfig;
      .unsubscribeTitle {
        @extend %titleTextHeading;
      }
      .errMsg {
        @extend %relBlock;
        color: red;
        font-size: 13px;
        margin-top: 5px;
      }
      .ql-container {
        height: calc(100% - 45px);
      }

      .ql-toolbar.ql-snow {
        .ql-formats:nth-child(4),
        .ql-formats:nth-child(5) {
          display: none;
        }
      }

      .ql-snow .ql-tooltip::before {
        content: "{{unsubscribeLink}}";
        color: $blue-violet;
      }

      .ql-snow .ql-tooltip[data-mode="link"]::before {
        content: "Insert unsubscribe link here";
      }

      .ql-snow .ql-tooltip.ql-editing input[type="text"],
      .ql-snow .ql-tooltip a.ql-preview,
      .ql-snow .ql-tooltip a.ql-action {
        display: none !important;
      }
      .ql-snow .ql-editing[data-mode="link"] a.ql-action {
        display: inline-block !important;
        &:after {
          border-right: 0;
          content: "Ok" !important;
          padding-right: 0;
          padding: 2px 8px;
          background: $blue-violet;
          border: 1px solid $blue-violet;
          color: $white;
          border-radius: 5px;
        }
      }
    }
  }
  .unsubscribeCheck {
    @extend %relBlock;
    padding: 5px 20px;
  }
  .unsubscribeBtnSection {
    @include flex-center();
    justify-content: left;
    padding: 0px 20px;
    margin-top: 10px;
    width: fit-content;
    .addUnsubscribeBtn {
      @extend %primaryBlueBtn;
    }
  }
}

/* ----- Custom URL Secton ----- */
.customUrlSection {
  @extend %relBlock;
  padding: 30px;
  height: 100%;
  -webkit-user-select: none;
  user-select: none;
  overflow-y: auto;
  h6.titleHeader {
    position: relative;
    display: block;
    padding-bottom: 20px;
    font-size: 15px;
    font-family: $font-regular;
    color: $c-66;
    font-weight: 400;
  }
  .customUrlBody {
    @extend %relBlock;
    height: calc(100% - 68px);
    overflow: auto;
    .featureDemoContentCard {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    .customUrlRow,
    .customUrlRowLoader {
      position: relative;
      border-top: 1px solid $c-ee;
      border-bottom: 1px solid $c-ee;
      @include wt-ht(100%, 60px);
      display: grid;
      grid-template-columns: calc(100% - 70px) 70px;
      align-items: center;
      padding: 0 15px;
      .domainName {
        @extend %relBlock;
        font-size: 15px;
        text-align: left;
        color: $c-66;
        @include ellipsis;
        &.linear-background {
          width: 240px;
        }
      }
      .wrapActionIcon {
        position: relative;
        @include flex-center;
        .linear-background {
          border-radius: 50%;
          @include wt-ht(30px, 30px);
        }
        span:first-child {
          margin-right: 5px;
        }
      }
    }
    .customUrlInfoText {
      position: relative;
      @include flex-center;
      margin-top: 10px;
      color: rgba(102, 102, 102, 0.65);
      i {
        position: relative;
        margin-right: 5px;
        font-size: 16px;
      }
      .text {
        font-size: 12px;
      }
    }
    .customUrlInfoTextLoader {
      position: relative;
      display: grid;
      grid-template-columns: 14px calc(100% - 14px);
      margin-top: 10px;
      .icon {
        @include wt-ht(14px, 14px);
        border-radius: 50%;
      }
      .text {
        margin-left: 5px;
        @include wt-ht(240px, 12px);
      }
    }
  }
}

.settingsPage {
  .contactAdminPopup {
    @include adminPopup();
  }

  .adminPopupMask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 199;
    background: #333;
    opacity: 0.75;
  }
}

/* ---- CALL RECORDING ---- */
.callRecordingWrapper {
  padding-top: 40px;
  .callRecordingToggleBtn {
    .toggleBtnSection {
      .loading {
        display: block;
        height: 20px;
        @include disable(1, unset);
      }
      &.disabledToggle {
        @include disable(0.5, pointer, unset, unset);
      }
      .rightPad {
        padding-right: 0;
      }
      .toggleText {
        margin-top: 2px;
        font-size: 16px;
        color: $c-66;
        font-weight: 400;
      }
    }
  }
}
