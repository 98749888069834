.initialWelcomePopupWrapper {
  color: $c-1d2026;
  @extend %welcomePopupCard;
  .card {
    width: 645px;
    max-width: 800px;
    max-height: 700px;
    overflow: auto;
    text-align: center;
    position: relative;
    .popupClose {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      padding: 2px;
      @include wt-ht(18px, 18px);
      @include flex-align-center;
      i {
        font-size: 16px;
      }
      &:hover {
        background-color: $c-dd;
        border-radius: 50%;
      }
    }
    .welcomePopupHeader {
      @include flex-center;
      flex-direction: column;
      padding: 30px 15px 25px;
      svg {
        width: 300px;
        height: 200px;
        margin-bottom: 20px;
      }
      .userName {
        font-size: 20px;
        color: #1d2026;
        font-weight: 900;
        padding: 12px 16px 13px;
      }
      .headerPopupText {
        font-size: 14px;
        color: #1d2026;
        padding: 5px;
        font-weight: 600;
        width: 100%;
        line-height: 1.6;
      }
      .orangeText {
        color: #f1473e;
      }
      .headerAdditionalText {
        font-size: 14px;
        color: #1d2026;
        width: 82%;
      }
    }
    .planHeading {
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      background-color: $c-f7f7f7;
      color: $c-1d2026;
      .planHeadingWrapper {
        display: flex;
        flex-direction: column;
        padding: 6px;
        .planHeadingTitle {
          font-size: 14px;
          padding: 5px;
        }
        .planItems {
          display: flex;
          flex-direction: row;
          padding: 8px 5px;
          font-weight: 100;
          gap: 10px;
          font-size: 14px;
          .planItem {
            display: flex;
            flex-direction: row;
            padding: 5px;
            gap: 10px;
            align-items: center;
            .planItemDescription {
              font-size: 14px;
              text-align: left;
            }
          }
        }
      }
      .upgradeAnytime {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $c-e6e6e6;
        .upgradeAnytimeWrapper {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
          padding: 5px 8px;
          color: $c-1d2026;
          font-size: 14px;
          font-weight: 600;
          .scaleFaster {
          }
        }
      }
    }
    .welcomePopupHeader,
    .welcomePopupFooter {
      .popupText {
        font-size: 12px;
        color: $c-66;
        margin-bottom: 20px;
        border: 1px solid $c-dd;
        border-radius: 5px;
        background-color: $c-f7f7f7;
        text-align: left;
        padding: 15px;
      }
      .letsGoBtn {
        background-color: $blue-violet;
        @include flex-center();
        justify-content: center;
        // height: 32px;
        margin: 24px auto;
        border-radius: 6px;
        padding: 13px 25px;
        gap: 10px;
        font-size: 14px;
      }
    }
  }
}
