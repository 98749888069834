.clients {
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 75px !important;
  padding-bottom: 75px !important;
  .backgroundPattern {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .container {
    width: 100%;
  }

  .sectionTitle {
    position: relative;
    color: hsl(0, 0%, 12%);
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
    font-size: 32px;
    width: 100% !important;

    .highlight {
      position: relative;
      display: block;
      color: #000000;
      font-weight: 600;
    }
  }

  .clients-list {
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    column-gap: 25px;
    row-gap: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding-top: 2rem;

    .client {
      position: relative;
      object-fit: contain;
      transition: all 0.3s ease;
      align-self: baseline;
      width: max-content;
      height: auto;
      transition: transform 0.3s ease-in-out;
    }
  }
}
