.progress-container {
  width: 75%;
  max-width: 300px;
  background-color: #e0e0e0;
  border-radius: 10px;
  height: 10px;
  position: relative;
  overflow: visible;
  margin: 15px 15px;

  .progress-bar {
    height: 100%;
    background: linear-gradient(to right, #a5b4fc, #3b82f6);
    border-radius: 10px 0 0 10px; // Only curve left side
    position: relative;
    display: flex;
    align-items: center;
    transition: width 0.5s ease-in-out;

    // Creates the smooth transition to the text bubble
    &::after {
      content: "";
      position: absolute;
      right: -6px; // Half of bubble's padding
      width: 12px; // Matches container height
      height: 100%;
      background: #3b82f6;
      // clip-path: polygon(0 0, 100% 50%, 0 100%);
      clip-path: path("M 0 0 L 6 0 A 3 3 0 0 1 6 12 L 0 12 z");
      // clip-path: path(
      //   "M 0 200 L 0,75 A 5,5 0,0,1 150,75 L 175,100 L 150,125 L 200,200 z"
      // );
      opacity: 0; /* Initially hidden */
      transition: opacity 0.3s ease-in-out;
    }

    .progress-text {
      font-size: 8px;
      font-weight: bold;
      color: white;
      position: absolute;
      left: 100%;
      background-color: #3b82f6;
      padding: 2px 6px;
      border-radius: 10px;
      // margin-left: 6px; // Connects to the arrow
      z-index: 1;
      white-space: nowrap;
      border: 2px solid white;
    }
  }
}
