.emailCard {
  background: transparent;
  border-radius: 8px;
  padding: 20px;
  width: 424px !important;
  max-width: 500px !important;
  border: 2px solid $c-ec;
  height: fit-content;
  .cardHeader {
    display: flex;
    flex-direction: row;

    gap: 10px;
    .iconWrapper {
      scale: 0.8;
    }
  }

  h3 {
    font-size: 16px;
    margin-bottom: 8px;
    color: $c-3b;
  }

  p {
    font-size: 14px;
    color: $c-666666;
    margin-bottom: 12px;
  }

  .linearProgessWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .linearProgessBody {
      .limit {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        margin-bottom: 5px;
        .limitContainer {
          display: flex;
          justify-content: center;
          flex-direction: row;
          gap: 3px;
          font-size: 13px;
          .freecallingIcon {
            display: flex;
            cursor: pointer;
            i {
              font-size: 16px;
            }
            .tooltipWrapper {
              display: none;
            }

            &:hover .tooltipWrapper {
              display: block;
            }
          }
        }
        .monthlyEmailCredits {
          .customTooltip {
            .tooltipText {
              color: $white;
            }
            .tooltipBody {
              background: #393a3d;
              top: 23px;
              right: -210px;
              width: 280px;
              color: $white;
            }
            .sharpPointer {
              background-color: #393a3d;
              top: 13px;
              right: -1px;
            }
          }
        }
        .dailyEmailCredits {
          .customTooltip {
            .tooltipText {
              color: $white;
            }
            .tooltipBody {
              background: #393a3d;
              top: 23px;
              color: $white;
              right: -220px;
              width: 280px;
            }
            .sharpPointer {
              background-color: #393a3d;
              top: 14px;
              right: -1px;
            }
          }
        }
        .emailValidation {
          .customTooltip {
            .tooltipText {
              color: $white;
            }
            .tooltipBody {
              background: #393a3d;
              top: 27px;
              color: $white;
              right: -212px;
              width: 280px;
            }
            .sharpPointer {
              background-color: #393a3d;
              top: 18px;
              right: -2px;
            }
          }
        }
        .monthlyEmailSendCredits {
          .customTooltip {
            .tooltipText {
              color: $white;
            }
            .tooltipBody {
              background: #393a3d;
              top: 23px;
              right: -201px;
              color: $white;
              width: 280px;
            }
            .sharpPointer {
              background-color: #393a3d;
              top: 14px;
              right: -1px;
            }
          }
        }
      }
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-start;
    padding: 15px 0;
    .buttonContainer {
      button {
        background: rgba(57, 83, 251, 0.1) !important;
        color: $blue-violet;
        border: 1px solid $blue-violet;
        padding: 8px 12px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        &:hover {
          background: $blue-violet !important;
          color: $white !important;
        }
      }
    }
  }
}
