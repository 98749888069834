.planCardSection {
  .planCardSectionPricingToogle {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 30px;
  }
  .checkboxContainer {
    position: relative;
    column-gap: 5px;
    justify-content: center;
    z-index: 4;
    transition: all 0.3s ease-in-out;
    @media (max-width: 767px) {
      display: none !important;
    }

    .checkboxWrapper {
      position: relative;
      width: 30px;
      height: 16px;
      overflow: hidden;
      border-radius: 100px;
      .knobs {
        position: absolute;
        inset: 0;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          width: 16px;
          height: 16px;
          font-weight: 700;
          text-align: center;
          line-height: 1;
          z-index: 2;
          background-color: #a7a7a7;
          border-radius: 50%;
          transition: all 0.3s ease,
            left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
        }
      }
      .layer {
        position: absolute;
        inset: 0;
        width: 100%;
        transition: all 0.3s ease;
        z-index: 1;
        border-radius: 100px;
        border: 1px solid #a7a7a7;
      }
      .checkbox {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 3;

        &:checked + .knobs:before {
          left: 15px;
          background-color: #3953fb;
        }

        &:checked ~ .layer {
          background-color: white;
          border: 1px solid #3953fb;
        }
      }
    }
    .leftText {
      position: relative;
      left: 23px;
    }
    .rightText,
    .leftText {
      position: relative;
      font-size: 18px;
      border: 1px solid #dddddd;
      color: #333333;
      border-radius: 16px;
      padding: 4px 16px;
      width: 120px;
      cursor: pointer;
      @media (min-width: 576px) {
        font-size: 16px;
      }

      &.checked {
        color: #3953fb;
        border: 1px solid #3953fb;
        background-color: #ebecf0;
        z-index: 2;
      }
    }
    .offerText {
      position: absolute;
      line-height: normal;
      font-size: 13px;
      right: 31px;
      top: -20px;
      margin: 0 auto;
      padding: 2px 8px 0;
      background-color: #00d274;
      width: 75px;
      z-index: 3;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      color: white;
      border-radius: 5px;
      opacity: 1;
      min-width: max-content;
      animation: opacity 0.5s linear;
      @media (min-width: 576px) {
        padding: 0 8px 0;
        font-size: 13px;
      }
    }
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .planCardWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 0px 20px 0px rgba(196, 196, 196, 0.2);

    .planCardItem {
      position: relative;
      height: 475px;
      min-height: 450px;
      .mostPopularBadge {
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        padding: 4px 8px;
      }
      .isPopularPlanCard {
        border: 1px solid $blue-violet !important;
        background-color: #ebecf0;
      }
      .planCard {
        border: 1px solid $c-dd;
        border-collapse: collapse;
        padding: 16px;
        height: 475px;
        min-height: 450px;
        .planName {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 14px;
        }
        .planSubText {
          font-size: 16px;
          color: $c-3b;
          margin-bottom: 12px;
        }
        .planDollar {
          font-size: 16px;
          align-self: flex-start;
        }
        .planAmount {
          font-size: 34px;
          font-weight: 600;
        }
        .planString {
          @include flex-center;
          justify-content: center;
          align-items: center;
          .flexColumn {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }
        .planAmountSubText {
          margin-top: 14px;
        }
        .planAmountSubText,
        .planAmountMonthlyText {
          font-size: 12px;
          font-weight: 400;
        }
        .customTooltip {
          position: relative;
          z-index: 100;
          right: -22px;
          top: 6px;

          .sharpPointer {
            border-top-right-radius: 2px;
            padding: 5px;
            border-right: 1px solid black;
            border-top: 1px solid black;
            right: 144px;
            background: black;
          }

          .tooltipBody {
            background: black;
            right: -15px;
            top: 3px;
            width: 200px;
            border-radius: 4px;

            .tooltipText {
              color: white;
              white-space: initial;
              padding: 6px;
              font-size: 11px;
              text-align: center;
            }
          }
        }
        .emailCredits,
        .phoneNumbers,
        .emailSends,
        .usCallingMinutes,
        .maxInvite {
          font-size: 14px;
          margin-bottom: 20px;
        }
        .emailCredits {
          margin-top: 20px;
        }
        .maxInvite {
          margin-bottom: 0;
        }
      }
    }
  }
}
